import React from 'react'
import { useNavigate } from 'react-router-dom'

function Leadstatus() {
  const navigate = useNavigate();
    const handleleadstatus = (leadstatus)=>{
      
        navigate("/leads", { state: { leadstatus } });
    }
    

  return (
   
          <ul
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "10%",
                            padding: "10px 20px",
                            backgroundColor: "rgba(215, 219, 255, 1)",
                            zIndex: "9999",
                            fontSize: "12px",
                            color: "black",
                          }}
                        >
                             
                          <li  onClick={()=>handleleadstatus("Interested")}>Interested</li>
                          <li onClick={()=>handleleadstatus("Not Interested")}>Not Interested</li>
                          <li  onClick={()=>handleleadstatus("No Response")}>No Response/Buy</li>
                          <li onClick={()=>handleleadstatus("Requested Info")}>Requested More Info</li>
                          <li  onClick={()=>handleleadstatus("Requested Call Back")}>
                            Requested Call Back
                          </li>
                          <li  onClick={()=>handleleadstatus("Invalid Number")}>Invalid Number</li>
                          <li  onClick={()=>handleleadstatus("Others")}>Others</li>
                        </ul>
 
  )
}

export default Leadstatus