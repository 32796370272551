import React, { useCallback, useEffect, useState} from "react";
import Loader from "../pages/Loader";
import { AiTwotoneDelete } from "react-icons/ai";
import { deleteProductServices } from "../controller/fetchApi";
import Toast from "./Toast";
import Deleteuserconfirmation from "./deleteuserconfirmation";


function ProductServicesTable({
  setPrDeleted,
  token,
  tblHead,
  productServicesData,
  loading,
}) {
  const [productId,setproductId] = useState(null)
     const [show, setshow] = useState(false);
      const [showToast, setShowToast] = useState({
            success: false,
            message: "",
            optmessage: "",
          });
        
          useEffect(() => {
            if (showToast.message) {
              const timer = setTimeout(() => {
                setShowToast({ success: false, message: "", optmessage: "" });
              }, 3000);
        
              return () => clearTimeout(timer);
            }
          }, [showToast]);

  const deleteProductandservices = useCallback(async (mpin) => {

    const res = await deleteProductServices(token,mpin, productId);
      if (res?.status == 200) {
        setPrDeleted(true);
        setshow(false)
      }
  
  }, [productId]);


  return (
    <div className="LeadRightSectionTable_body table-responsive p-0" style={{height:"100%",overflow:"hidden"}}>
      <div className="LeadRightSectionTable_body table-responsive" style={{height:"100%"}}>
        <table className="table mytable">
          <thead>
            <tr>
              <th scope="col">{tblHead.firstHead}</th>
              <th scope="col">{tblHead.secondHead}</th>
              <th scope="col">{tblHead.thirdHead}</th>
              <th scope="col">{tblHead.fourthHead}</th>
            </tr>
          </thead>
          {loading ? (
            <Loader />
          ) : (
            <tbody className="dashboard_section1_tableBody ">
              {productServicesData && productServicesData.length > 0 ? (
                productServicesData?.map((data, index) => (
                  <tr key={data.id}>
                    <td>{index + 1}</td>
                    <td>{data?.type}</td>
                    <td>{data?.name}</td>
                    <td>
                      <AiTwotoneDelete
                        style={{
                          height: "30px",
                          width: "25px",
                          cursor: "pointer",
                        }}
                        onClick={() =>{ setshow(data?.id);
                          setproductId(data?.id)
                        }}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" style={{ paddingLeft: "30px" }}>
                    No product and service available{" "}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      {show && (
        <Deleteuserconfirmation
        setShowToast={setShowToast}
          show={show}
          setShow={setshow}
          handleDelete={deleteProductandservices}
          deleteId={show}
          sourceName="product and service"
        />
      )}
       <Toast showToast={showToast} setShowToast={setShowToast}/>
    </div>
  );
}

export default ProductServicesTable;
