import React, { useState, useEffect, useCallback, lazy, Suspense } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import CreatedUserTable from "../components/CreatedUserTable";
// Controller Method
import { getAllUsersMadeByAdmin } from "../controller/fetchApi";
import Loader from "./Loader";
import { Link, useNavigate } from "react-router-dom";
import { MdAdd } from "react-icons/md";
// import Signup from "./Signup";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Goback from "../components/goback";
// import { Pagination } from "react-bootstrap";
import Pagination from "../components/pagination";
const Signup = lazy(() => import("./Signup"));

const CreatedUser = () => { 
  
  const navigate = useNavigate();
  const [createUser, setcreateUser] = useState(false);
  // Start Toast -------
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });

  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  // TokenId
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  const userrole = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;

  // Set Contact Costumer Id in main Conntact.jsx
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(12);

  //reset user data
  const [currdata, setcurrdata] = useState(false);
  //  Get All Users Api
  const [getAllUsers, setAlluser] = useState(null);
  //set loader
  const [loading, setloading] = useState(true);

  const getAllUser = useCallback(async () => {
    const result = await getAllUsersMadeByAdmin(tokenId, pageNo, pageSize);
    if (result) {
      setAlluser(result);
      setloading(false);
    }
  }, [tokenId, setAlluser, pageNo, pageSize]);

  useEffect(() => {
    getAllUser();
  }, [getAllUser, currdata]);

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
     
      <div className="dashboard_content_wrapper">
        <div className="dashboard_leads_btn_mainDiv">
     
          <Goback />
          {["SUPERADMIN", "ADMIN"].includes(userrole) && (
 
            <div className="dashboard_leads_btns_div">
              <div class="actionbtn">
                <button
                  class="default-btn"
              
                  onClick={() => setcreateUser(true)}
                >
                  <Link className="dashboard_leads_create_link" to="#">
                    <span>
                      <MdAdd />
                    </span>
                    Create User
                  </Link>
                </button>
              </div>
            </div>
            // </div>
          )}
        </div>
        {/* Table Div */}

      {console.log(' getAllUsers getAllUsers getAllUsers', getAllUsers)}  
          <div class="responsivetable">
            <table class="table mytable">
              <CreatedUserTable
                loading={loading}
                setcurrdata={setcurrdata}
                tblHead={{
                  firstHead: "Sr. No",
                  secondHead: "Name",
                  thirdHead: "Email",
                  seventhHead: "Role",
                  fourthHead: "Contact",
                  fifthHead: "Show",
                  sixthHead: "Permissions",
                  eighthHead: "Action",
                  ninthHead: "Activity",
                }}
                data={getAllUsers?.data}
                getAllUser={getAllUser}
                redirectLink="/role&permission"
              />
            </table>
          </div>
       



        <Pagination
          maxData={getAllUsers?.data?.totalElements}
          totalPages={getAllUsers?.data?.totalPages}
          totalElements={getAllUsers?.data?.totalElements}
          showingElements={getAllUsers?.data?.currentPageElements}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageSize={pageSize}
          setPagesize={setPagesize}
        />
      </div>

    
      {createUser && (
        <div className="NEWMODALupdate">
          <div className="actmodalupdate">
            <div className="closemodalupdate">
              <button
                style={{
                  fontSize: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  color: "grey",
                  border: "none",
                }}
                onClick={() => setcreateUser(null)}
              >
                X
              </button>
            </div>
            <div className="modalContent">
              <Suspense fallback={<div>Loading...</div>}>
                <Signup />
              </Suspense>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatedUser;
