import React, { useContext, useEffect, useState } from "react";
// React Router Dom
import { Link } from "react-router-dom";
import leadIdContext from "../../pages/LeadIdContext";
import { FaRegEye } from "react-icons/fa";
import { HiPencilSquare } from "react-icons/hi2";
import whatsappIcon from "../../images/whatsapp_icon.png";
import callIcon from "../../images/call_icon.png";
import Loader from "../../pages/Loader";
import LeadStatusupdate from "./LeadStatusupdate";
import update_icon from "../../images/update_icon.png";

const LeadsRightSectionTable = ({
  setupdatedLeadStatus,
  tblHead,
  redirectLink,
  getAllLeadData,
  setupdateleadId,
  updateleadId,
  pageNo,
  pageSize,
  loading,
  setShowToast,
}) => {
  const [updateStatus, setupdateStatus] = useState(null);
  const [rect, setRect] = useState(null);

  // Get leadCostumerId From LeadSection Table for delete Data From Table
  const { leadCostumerId, setLeadCostumerId } = useContext(leadIdContext) || []; //--- here used empty array because when id is undefined when do not cause error
  const [isMasterChecked, setIsMasterChecked] = useState(false);

  const userIdTokenData = JSON.parse(localStorage.getItem("user"));

  const userrole = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;
  // Handle Single Check Box For Single Updation And Id get and send Start ------
  const handleCheckboxChange = (data) => {
    const leadId = data?.id;
    const isSelected = leadCostumerId.includes(leadId);
    if (isSelected) {
      setLeadCostumerId(leadCostumerId.filter((id) => id !== leadId));
      setupdateleadId(null);
    } else {
      setLeadCostumerId([...leadCostumerId, leadId]);
      setupdateleadId(data);
    }
  };
  // Handle Master Checkbox Change Start -----
  const handleMasterCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsMasterChecked(isChecked);
    const allLeadIds = getAllLeadData?.content?.map((data) => data.id) || [];
    if (isChecked) {
      setLeadCostumerId(allLeadIds);
    } else {
      setLeadCostumerId([]);
    }
  };
  // Lead Status Color
  const getStatusClassName = (status) => {
    switch (status.toLowerCase()) {
      case "lead":
        return "lead-table-leadStatus-lead";
      case "contacted":
        return "lead-table-leadStatus-contacted";
      case "deal":
        return "lead-table-leadStatus-deal";
      default:
        return "";
    }
  };

  // Update master checkbox state when leadCostumerId changes
  useEffect(() => {
    const allLeadIds = getAllLeadData?.content?.map((data) => data.id) || [];
    if (allLeadIds.length === 0) return;
    if (leadCostumerId.length === allLeadIds.length) {
      setIsMasterChecked(true);
    } else {
      setIsMasterChecked(false);
    }
  }, [leadCostumerId, getAllLeadData]);

  const openWhatsApp = (mobileNumber) => {
    // const phoneNumber = '6377049235'; // Replace with the phone number in international format
    const message =
      "Hello, I am reaching out regarding your inquiry. Please let us know how we can assist you further!";
    const isMobile = /iPhone|Android|iPad/i.test(navigator.userAgent);

    // WhatsApp URL
    const url = isMobile
      ? `https://wa.me/${mobileNumber}?text=${encodeURIComponent(message)}` // Redirects to the mobile app
      : `https://web.whatsapp.com/send?phone=${mobileNumber}&text=${encodeURIComponent(
          message
        )}`; // Opens WhatsApp Web

    // Redirect to WhatsApp
    window.open(url, "_blank");
  };

  const handleStatusUpdate = (event, leadId) => {
    event.stopPropagation();
    const rect = event.target.getBoundingClientRect();
    setupdateStatus((prev) => (prev === leadId ? null : leadId));
    setRect(rect);
  };

  return (
    <div className="LeadRightSectionTable_body table-responsive" style={{height:"100%"}}>
      <table className="table mytable">
        <thead>
          <tr>
            <th
              scope="col"
              className="text-center"
              style={{ cursor: "context-menu" }}
            >
              <div className="wrap-check-29">
                <div className="cbx">
                  <input
                    id="cbx-29"
                    type="checkbox"
                    onChange={handleMasterCheckboxChange}
                    // onClick={handleMasterCheckboxChange}
                    checked={isMasterChecked}
                  />
                  <label htmlFor="cbx-29" />
                  <svg width={15} height={14} viewBox="0 0 15 14" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2" />
                  </svg>
                </div>
                {/* Gooey*/}
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <defs>
                    <filter id="goo-12">
                      <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation={4}
                        result="blur"
                      />
                      <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                        result="goo-12"
                      />
                      <feBlend in="SourceGraphic" in2="goo-12" />
                    </filter>
                  </defs>
                </svg>
              </div>
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ cursor: "context-menu" }}
            >
              {tblHead.seventhHead}
            </th>
            <th
              scope="col"
              style={{ textAlign: "center", cursor: "context-menu" }}
            >
              {tblHead.firstHead}
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ cursor: "context-menu" }}
            >
              {tblHead.callHead}
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ cursor: "context-menu" }}
            >
              {tblHead.contactHead}
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ cursor: "context-menu" }}
            >
              {tblHead.zerohead}
            </th>

            {/* <th scope="col" className="text-center" style={{cursor:"context-menu"}}>
                {tblHead.secondHead}
              </th> */}

            <th
              scope="col"
              className="text-center"
              style={{ cursor: "context-menu" }}
            >
              {tblHead.thirdHead}
            </th>
            {/* <th scope="col" className="text-center">
                {tblHead.fourthHead}
              </th> */}
            {/* <th scope="col" className="text-center" style={{cursor:"context-menu"}}>
                {tblHead.fifthHead}
              </th> */}
            {/* <th scope="col" className="text-center" style={{cursor:"context-menu"}}>
                {tblHead.sixthHead}
              </th> */}
            <th
              scope="col"
              style={{ textAlign: "left", cursor: "context-menu" }}
            >
              {tblHead.zeroonehead}
            </th>
            <th
              scope="col"
              style={{ textAlign: "left", cursor: "context-menu" }}
            >
              {tblHead.statusHead}
            </th>
          </tr>
        </thead>
        {loading ? (
          <Loader />
        ) : (
          <tbody>
            {getAllLeadData && getAllLeadData?.content ? (
              getAllLeadData?.content?.map((data, index) => (
                <tr
                  className={data.assignedTo ? "" : "table-Light"}
                  key={data.id}
                  onClick={() => {
                    localStorage.setItem("leadId", data.id);
                  }}
                >
                  {/* <td className="text-center">
                    <span className="Link-button-leads">
                    <span style={{cursor:"context-menu",border:"1px solid #9c9c9c",padding:"2px 4px",borderRadius:"3px"}}>{pageNo*pageSize+index+1}</span>
                    </span>
                  </td> */}
                  <td
                    className="text-left"
                    onClick={() => handleCheckboxChange(data)}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckIndeterminate"
                      // onChange={() => handleCheckboxChange(data)}
                      checked={leadCostumerId?.includes(data.id)}
                    />
                  </td>
                  <td className="text-center">
                    <Link
                      to={redirectLink}
                      className="Link-button-leads text-center"
                    >
                      {/* <div className="leads_table_id_col rounded"> */}
                      <span className="leads_table_id_text">LD-{data.id}</span>
                      {/* </div> */}
                    </Link>
                  </td>
                  <td
                    className="col-xl-2 text-center"
                    style={{ cursor: "context-menu", wordBreak: "break-word" }}
                  >
                    <span className="Link-button-leads">{data.firstName}</span>
                  </td>
                  <td className="text-center">
                    <a href={`tel:${data.mobile}`}>
                      <img
                        src={callIcon}
                        width="25px"
                        onClick={(event) => event.stopPropagation()}
                      />{" "}
                    </a>
                  </td>
                  <td className="text-center">
                    <span style={{ cursor: "pointer", marginRight: "15px" }}>
                      <img
                        width="25px"
                        src={whatsappIcon}
                        alt="Wtsp"
                        loading="eager"
                        onClick={(event) => {
                          openWhatsApp(data.mobile);
                          event.stopPropagation();
                        }}
                      />
                    </span>
                  </td>
                  {/* *************** */}
                  <td
                    className="text-center col-xl-3"
                    style={{ cursor: "context-menu" }}
                  >
                    {/* <img
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                      src={(['PROJECTMANAGER','SALESEXECUTIVE']?.includes(userrole)?data?.leadAssignedByImage:data?.leadAssignedToImage)||"https://www.dgvaishnavcollege.edu.in/dgvaishnav-c/uploads/2021/01/dummy-profile-pic.jpg"}
                      alt="us"
                    /> */}
                    <span
                      className="Link-button-leads"
                      style={{ cursor: "context-menu" }}
                    >
                      {/* {data.assignedTo || "Not Assigned"} */}
                      {/* {data?.assignedBy?data?.assignedBy:data?.assignedTo?data?.assignedTo:"Not Assigned"} */}
                      {(["PROJECTMANAGER", "SALESEXECUTIVE"]?.includes(userrole)
                        ? data?.assignedBy
                        : data?.assignedTo) || "Not Assigned"}
                    </span>
                  </td>

                  {/* <td className="text-center">
                    <span className="Link-button-leads">
                    <span style={{cursor:"context-menu"}}>  {data.mobile}</span>
                    </span>
                  </td> */}

                  <td className="text-center">
                    <span
                      className="Link-button-leads"
                      style={{ cursor: "context-menu" }}
                    >
                      {data.leadSource}
                    </span>
                  </td>
                  {/* <td className="text-center">
                    <Link
                      to={redirectLink}
                      className={`Link-button-leads ${getStatusClassName(
                        data.leadStatus
                      )}`}
                    >
                      <span> {data.leadStatus}</span>
                    </Link>
                  </td> */}
                  {/* <td className="text-center">
                    <Link to={redirectLink} className="Link-button-leads">
                      <FaRegEye className="showDetailEye fs-4" />
                    </Link>
                  </td> */}
                  {/* <td className="text-center">
                    <Link to="/create-contact" state={{ leadId: data.id }}>
                      <HiPencilSquare className="lead-table-contact-action-icon fs-4" />
                    </Link>
                  </td> */}
                  <td style={{ cursor: "context-menu" }}>
                    <span className="Link-button-leads">{data.createdAt}</span>
                  </td>
                  <td style={{ cursor: "context-menu", position: "relative" }}>
                    <span
                      className="Link-button-leads"
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        paddingLeft: "12px",
                        cursor: "pointer",
                        padding: "8px 12px",
                      }}
                      onClick={(event) => {
                        // event.stopPropagation();
                        handleStatusUpdate(event, data?.id);
                      }}
                    >
                      <img width="18px" src={update_icon} alt="..." />
                    </span>
                    {updateStatus === data.id && (
                      <LeadStatusupdate
                        rect={rect}
                        setupdatedLeadStatus={setupdatedLeadStatus}
                        setupdateStatus={setupdateStatus}
                        leadId={data.id}
                        setShowToast={setShowToast}
                      />
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12">No Lead Data At this Time</td>
              </tr>
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default LeadsRightSectionTable;
