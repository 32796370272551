import React from "react";
// import "../styles/ErrorPage.css";
import { useNavigate } from "react-router-dom";
import errorImg from "../images/error_img.webp";
import { IoArrowBackCircleOutline } from "react-icons/io5";


const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="error_404_page">
         <span onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </span>
      <div className="error_div">
        <img src={errorImg} alt="Error Img" className="img-fluid " />
        <p className="error_page_heading">Page Not Found</p>
        <p className="error_page_text">
          <span>Oops !</span> May be search wrong url
        </p>
        <button className="error_page_btn">
          <span
            className="error_page_btn_link"
            onClick={() => navigate(-1)}
          >
            Back Home
          </span>
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
