import React, { useEffect, useState } from "react";
import { getUserImage } from "../../controller/fetchApi";
import { Link } from "react-router-dom";
import logoimage from "../../images/crm.png";

function Logo({ updatelogo, shrinkSidebar }) {
  const [logo, setlogo] = useState(null);

  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const roles = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;
  const tokenId = userIdTokenData?.data?.token;

  const userImage = async () => {
    const res = await getUserImage(tokenId);
    if (res) {
      //   setImage(res?.data?.data?.userImage);
      setlogo(res?.data?.data?.companyLogo);
    }
  };
  useEffect(() => {
    userImage();
  }, [updatelogo]);
  return (

    <div>
      <Link className="logo_crmtext " to="/dashboard">
        {/* {logo ? <img width="45px" className="LOgo_Image" src={logo} alt="C" />:<img width="45px" className="LOgo_Image" src={logoimage} alt="C" />} */}
        {/* {logo ? <img width="45px" className="LOgo_Image" src={logo} alt="C" />:<img width="45px" className="LOgo_Image" src={logoimage} alt="C" />} */}
        <img width="45px" className="LOgo_Image" src={logoimage} alt="C" />
        {shrinkSidebar && (
          <> 
            CR<span>M</span>
          </>
        )}
      </Link>
    </div>
  );
}

export default Logo;
