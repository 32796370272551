import React, { useState, useEffect } from "react";
// Bootstrap
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import './App.css';
// React Router Dom
import { BrowserRouter } from "react-router-dom";
// Routes
import AllRoutes from "./routes/AllRoutes";
import permissionContext from "./pages/PermissionsContext";
const App = () => {
  // Get Permissions From LocalStorage
  // const userIdTokenData = JSON.parse(localStorage.getItem("user"));

  const [permissions, setPermissions] = useState({
    leadsPermission: null,
    contactsPermission: null,
    meetingsPermission: null,
    dealsPermission: null,
    accountsPermission: null,
    callsPermission: null,
    tasksPermission: null,
  });



  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    if (modules) {
      const newPermissions = { ...permissions };
       
      modules.forEach((module) => {
        const moduleName = module.module.toLowerCase() + "Permission";
        if (newPermissions.hasOwnProperty(moduleName)) {
          newPermissions[moduleName] = module.permissions;
        }
      });

      setPermissions(newPermissions);
    }
    
  }, [modules])

  useEffect(() => {
    // Function to fetch and set modules from localStorage
    const checkAndSetModules = () => { 
    
      const userIdTokenData = JSON.parse(localStorage.getItem('user'));
      if (userIdTokenData?.data?.roleAndPermissions?.roles?.[0]?.modules) {
        setModules(userIdTokenData.data.roleAndPermissions.roles[0].modules);
        setLoading(false); // Set loading to false once data is available
        return true;
      }
      return false;
    };

    // Initial check
    if (checkAndSetModules()) {
      return;
    }

    // Set an interval to check for data
    const intervalId = setInterval(() => {
      if (checkAndSetModules()) {
        clearInterval(intervalId); // Clear the interval once data is available
      }
    }, 1000); // Check every 1 second

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <permissionContext.Provider value={permissions}>
        <BrowserRouter>
          <AllRoutes />
        </BrowserRouter>
      </permissionContext.Provider>
    </>
  );
};

export default App;
