import React, { useCallback, useEffect, useState } from "react";
import { AddProduct } from "../controller/fetchApi";

function AddProductService({ setAddProduct, token, setPrAdded, setShowToast }) {
  const [producttype, setProducttype] = useState("");
  const [productname, setProductname] = useState("");
  const [error, seterror] = useState("");

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const response = await AddProduct(producttype, productname, token);
    setPrAdded(true);
    // setAddProduct(false);
    if (response.status === 200) {
      setShowToast({
        success: true,
        message: `${producttype} Added Successfully`,
        optmessage: `Adding ${producttype}...`,
      });
    }
  });
 
  useEffect(()=>{
    const timer = setTimeout(() => {
      seterror("")
    }, 1000);
    return ()=>clearTimeout(timer)
  },[error])
  // input validation
  const pattern = /^[a-zA-Z0-9/s]*$/;
  const handleInput = (e) => {
    const value = e.target.value;
    const newEntry = value.slice(-1);
    if (pattern.test(newEntry)) {
      setProductname(value);
      seterror("");
    } else {
      seterror("Please enter only alphabet or digit.");
    }
  };
  return (
    <div className="productservices_modal">
      <div className="productservices_modal_content">
        <h4>Add product&services</h4>
        <form className="productservices_form" onSubmit={handleSubmit}  style={{position:"relative"}}>
          <div className="productservices_input_div">
            <select onChange={(event) => setProducttype(event.target.value)}>
              <option value="" hidden>
                Select type
              </option>
              <option>Product</option>
              <option>Service</option>
            </select>
            <div className="productservices_input_div_input">
              <input
                type="text"
                placeholder={
                  producttype
                    ? `Enter ${producttype.toLowerCase()} name...`
                    : "Select type...."
                }
                id="productInputservice1"
                aria-describedby="emailHelp"
                disabled={!producttype}
                value={productname}
                // onChange={(event) => setProductname(event.target.value)}
                onInput={handleInput}
              />
            </div>
            {/* {error&&<small style={{position:"absolute",color:"red",top:"50%",left:"10px"}}>{error}</small>} */}
          </div>
          {error&&<small style={{position:"absolute",color:"red",top:"40%",left:"10px"}}>{error}</small>}
          <div>
            <button
              type="button"
              className="btn btn-primary addproductbtn"
              onClick={() => setAddProduct(false)}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary float-end addproductbtn"
            >
              +Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddProductService;
