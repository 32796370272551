import React, { useState, useEffect, useCallback, useContext } from "react";

// Import React Icons
import { MdAdd } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsPencil, BsTrash } from "react-icons/bs";
import { TbFileDownload } from "react-icons/tb";
// Import Components
import AccountTable from "../../components/AccountTable";
import UpdateAccount from "../dashboardPages/UpdateAccount";
// Import Toast
import Toast from "../../components/Toast";
// Import Permissions Context From app.js
import permissionContext from "../PermissionsContext";
// Import api function from controller
import {
  getAllAccount,
  downloadAccount,
  getSingleAccount,
  deleteAccount,
} from "../../controller/fetchApi";
// Import Instance from React Router Dom
import { Link, useNavigate } from "react-router-dom";
import Deleteuserconfirmation from "../../components/deleteuserconfirmation";
import Loader from "../Loader";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Goback from "../../components/goback";
import Pagination from "../../components/pagination";


const Accounts = () => { 
 
  
  const navigate = useNavigate();
  //  Get Account Permission'
  const { accountsPermission } = useContext(permissionContext);
  // Start Toast Code -------
  const [showToast, setShowToast] = useState({ success: false, message: "", optmessage: '' });

   useEffect(() => {
      if (showToast.message) {
        const timer = setTimeout(() => {
          setShowToast({ success: false, message: "", optmessage: "" });
        }, 3000);
  
        return () => clearTimeout(timer);
      }
    }, [showToast])
  // Set Account Costumer Id  to send table
  const [accountCostumerId, setAccountCostumerId] = useState([]);
  // Get User details from local storage
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(12);
  const accountId = JSON.parse(localStorage.getItem("accountId"));
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;
  const [getAllAccountData, setAllAccountData] = useState([]);

  // Pagination Function Code------
  const [pageRangeStart, setPageRangeStart] = useState(0);
  const totalPages = getAllAccountData?.totalPages || 1;
  const totalElements = getAllAccountData?.totalElements;
  const showingElements = getAllAccountData?.numberOfElements;
  const pagesToShow = 6;
  const handleNextPageClick = () => {
    const newPageNo = pageNo + 1;
    if (newPageNo < totalPages) {
      setPageNo(newPageNo);
      if (newPageNo >= pageRangeStart + pagesToShow) {
        setPageRangeStart(pageRangeStart + pagesToShow);
      }
    }
  };
  const handlePreviousPageClick = () => {
    const newPageNo = pageNo - 1;
    if (newPageNo >= 0) {
      setPageNo(newPageNo);
      if (newPageNo < pageRangeStart) {
        setPageRangeStart(pageRangeStart - pagesToShow);
      }
    }
  };
  const handlePageClick = (index) => {
    setPageNo(index);
    if (index >= pageRangeStart + pagesToShow) {
      setPageRangeStart(pageRangeStart + pagesToShow);
    } else if (index < pageRangeStart) {
      setPageRangeStart(pageRangeStart - pagesToShow);
    }
  };
  //set loader
  const [loading, setloading] = useState(true)
  //  Get All Account Data
  const getAccountData = useCallback(async () => {
    try {
      const res = await getAllAccount(tokenId, pageNo, pageSize);
      setAllAccountData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false)
    }
  }, [tokenId, pageNo, pageSize, setAllAccountData]);
  // Download Account Api
  const handleDownloadAccounts = async () => {
    try {
      await downloadAccount(uid, setShowToast, tokenId);
    } catch (error) {
      console.log("Error downloading account", error);
    }
  };
  // Update Btn Click Action Start--------
  const [defaultValue, setDefaultValue] = useState([]);
  const handleUpdateAccount = async () => {
    try {
      console.log("Enter FUnction SingleLead Update Account", accountId);
      const singleLeadResult = await getSingleAccount(accountId, tokenId);
      if (singleLeadResult) {
        setDefaultValue(singleLeadResult);
      } else {
        setDefaultValue([]);
      }
    } catch (error) {
      console.log("LeadRightSection SingleLead :", error);
      setDefaultValue([]);
    }
  };
  // Define the callback function to fetch updated data
  const handleUpdateSuccess = async () => {
    try {
      await getAccountData();
    } catch (error) {
      console.log("Error fetching updated data", error);
    }
  };

  //user id for deletion and confirmation for deletion
  const [dellead, setdellead] = useState({
    leadId: null, setLeadId: null
  });
  const [show, setshow] = useState(false)
  // Handle Delete Account Api
  const handleDeleteAccount = async () => {
    if (accountCostumerId.length) {
      try {
        await deleteAccount(accountCostumerId, setShowToast, tokenId);
        if (deleteAccount) {
          getAccountData();
          setAccountCostumerId([]);
          console.log("delete Successfully LeadRightSection", deleteAccount);
        }
      } catch (error) {
        const errorMessage = error.message;
        console.log("Error deleting Single Account", errorMessage);
      } finally {
        setAccountCostumerId([])
        setshow(false)
      }
    } else {
      setShowToast({ success: true, message: "Select account to delete.", optmessage: 'Deleting account.' })
    }

  };
  useEffect(() => {
    getAccountData();
  }, [getAccountData]);

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
      {/* <Link onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </Link> */}
      <div className="dashboard_content_wrapper">
        {/* Btn div */}
        <div className="dashboard_leads_btn_mainDiv">
          {/* <Link onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </Link> */}
          <Goback />
          <div className="dashboard_leads_btns_div">
            <div className="dashboard_leads_action_btn_div">
              <div className="actionbtn">

                <button
                  className="default-btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Actions
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="editDeleteDropdown"
                  >
                    {/* Update Btn */}
                    {accountsPermission?.includes("Update") ? (
                      <li
                        data-bs-toggle="modal"
                        data-bs-target="#updateAccountModal"
                      >
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            handleUpdateAccount();
                          }}
                        >
                          <BsPencil className="dashboard_section1_table_editBtn" />
                          Edit
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* Delete Btn */}
                    {accountsPermission?.includes("Delete") ? (
                      <li>
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            //handleDeleteAccount(accountCostumerId,setAccountCostumerId)
                            if (accountCostumerId?.length) {
                              setshow(true)
                              setdellead({
                                leadId: accountCostumerId, setLeadId: setAccountCostumerId
                              })
                            } else {
                              setShowToast({ success: true, message: "Select Contact to delete.", optmessage: "Deleting contact." })
                            }
                          }}
                        >
                          <BsTrash className="dashboard_section1_table_deleteBtn" />
                          Delete
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* Download Btn */}
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => handleDownloadAccounts()}
                      >
                        <TbFileDownload className="dashboard_section1_table_deleteBtn" />
                        Download Accounts
                      </span>
                    </li>
                  </ul>
                </button>
              </div>

            </div>


            {/* Create Btn */}
            {accountsPermission?.includes("Create") ? (
              <div className="dashboard_leads_create_btn_div" title="Create new account">

                <div class="createbtn">

                  <button className="default-btn">
                    <Link
                      className="dashboard_leads_create_link"
                      to="/create-account"
                    >
                      <span>
                        <MdAdd />
                      </span>
                      New Account
                    </Link>
                  </button>
                </div>

              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Table Div */}
        <div className="dashboard_leads_table_div">

          <AccountTable
            loading={loading}
            tblHead={{
              firstHead: "Account Name",
              secondHead: "Account Number",
              thirdHead: "Account Owner",
              fourthHead: "Annual Revenue",
              fifthHead: "Account Site",
              sixthHead: "View",
              seventhHead: "Deal Action",
              eighthHead: "Lead Id",
            }}
            redirectLink="/account-details"
            getAllAccountData={getAllAccountData}
            accountCostumerId={accountCostumerId}
            setAccountCostumerId={setAccountCostumerId}
            data="Pankaj Swami Vaishnav"
          />

        </div>
        {/* Pagination Div */}
        <Pagination
          maxData={totalElements}
          totalPages={totalPages}
          totalElements={totalElements}
          showingElements={showingElements}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageSize={pageSize}
          setPagesize={setPagesize}
        />
        {/* <div className="dashboard_leads_pagination_div">
          <nav aria-label="...">
            <ul className="pagination">
              
              <li className="page-item dashboard_leads_pagination_pageItem">
                <a
                  className="page-link"
                  href="#!"
                  onClick={handlePreviousPageClick}
                >
                  <IoIosArrowBack />
                </a>
              </li>

           
              {Array.from({ length: pagesToShow }, (_, index) => {
                const pageIndex = pageRangeStart + index;
                return (
                  pageIndex < totalPages && (
                    <li
                      key={pageIndex}
                      className={`page-item ${
                        pageIndex === pageNo ? "active" : ""
                      } dashboard_leads_pagination_pageItem`}
                    >
                      <a
                        className="page-link"
                        href="#!"
                        onClick={() => handlePageClick(pageIndex)}
                      >
                        {pageIndex + 1 < 10
                          ? `0${pageIndex + 1}`
                          : pageIndex + 1}
                      </a>
                    </li>
                  )
                );
              })}

           
              <li className="page-item dashboard_leads_pagination_pageItem">
                <a
                  className="page-link"
                  href="#!"
                  onClick={handleNextPageClick}
                >
                  <IoIosArrowForward className="btn_IoIosArrowForward" />
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
        {/*Update Account  Modal */}
        <>
          <div
            className="modal fade modal-xl"
            id="updateAccountModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header w-100">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <UpdateAccount
                    accountCostumerId={accountCostumerId}
                    defaultValue={defaultValue}
                    onUpdateSuccess={handleUpdateSuccess}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>

        <Deleteuserconfirmation
          show={show}
          setShow={setshow}
          handleDelete={handleDeleteAccount}
          sourceName="Contact"
        />
        {/* Toast */}
        <Toast showToast={showToast} setShowToast={setShowToast} />
      </div>
    </div>
  );
};

export default Accounts;
