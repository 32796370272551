import { Link } from "react-router-dom";
import Goback from "../components/goback";
import Pagination from "../components/pagination";
import { MdAdd } from "react-icons/md";
import ProductServicesTable from "../components/ProductServicesTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import AddProductService from "../components/addProduct&service";
import { getProductServices } from "../controller/fetchApi";
import Toast from "../components/Toast";

const Productlist = () => {
   const [addproduct,setAddProduct] = useState(false);
   const [productServicesData,setProductServicesData] = useState([]);
   const [loading,setLoading] = useState(true);
   const [prDeleted,setPrDeleted] = useState(false);
   const [prAdded,setPrAdded] = useState(false);
    const [showToast, setShowToast] = useState({
       success: false,
       message: "",
       optmessage: "",
     });
   
     useEffect(() => {
       if (showToast.message) {
         const timer = setTimeout(() => {
           setShowToast({ success: false, message: "", optmessage: "" });
         }, 3000);
   
         return () => clearTimeout(timer);
       }
     }, [showToast]);
  //  get token from local storage
   const userIdTokenData = useMemo(
       () => JSON.parse(localStorage.getItem("user") || "{}"),
       []
     );
   const token = userIdTokenData?.data?.token;
//  get product and services data
 const productServicesDataA =useCallback(async ()=>{
  try{
    const response = await getProductServices(token);
    setProductServicesData(response?.data?.data);
  }catch(error){
    console.log(error)
  }
  finally{
    setLoading(false);
  }
 },[])

 useEffect(()=>{
  productServicesDataA();
  setPrDeleted(false);
  setPrAdded(false);
 },[prDeleted,prAdded])
  
  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container" style={{overflow:"hidden"}}>
      <div className="dashboard_content_wrapper" style={{paddingBottom:"20px"}}>
        <div className="dashboard_leads_btn_mainDiv">
          <Goback />
          <div className="dashboard_leads_btns_div">
            {/* Create Btn */}
            <div className="dashboard_leads_create_btn_div">
              <button className="default-btn">
                <span className="dashboard_leads_create_link" onClick={()=>setAddProduct(true)}>
                  <span>
                    <MdAdd />
                  </span>
                  Add product&services
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* Table Div */}
        <div className="dashboard_leads_table_div">
          <ProductServicesTable
          setPrDeleted={setPrDeleted}
          token={token}
          loading={loading}
            productServicesData={productServicesData}
            tblHead={{
              firstHead: "Sr No.",
              secondHead: "Type",
              thirdHead: "Name",
              fourthHead: "Delete",
            }}
          />
        </div>
      </div>
      {addproduct&&(<AddProductService setAddProduct={setAddProduct} setPrAdded={setPrAdded} token={token} setShowToast={setShowToast}/>)}
      <Toast showToast={showToast} setShowToast={setShowToast}/>
    </div>
  );
};

export default Productlist;
