import Toast from "./Toast";
import { useFormik } from "formik";
import { BsBuildingsFill } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
import { FaTreeCity } from "react-icons/fa6";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { createCompany, updateCompanyDetail } from "../controller/fetchApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CreateCompanySchema } from "../schema/FormValidation";

const CreateCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const companydetail = location.state?.userData;

  // Start Toast Code -------
  const [showToast, setShowToast] = useState({ success: false, message: "" ,optmessage:''});
  // Function to hide the toast after 3 seconds
  useEffect(() => {
     if (showToast.message) {
       const timer = setTimeout(() => {
         setShowToast({ success: false, message: "", optmessage: "" });
       }, 3000);
 
       return () => clearTimeout(timer);
     }
   }, [showToast])
  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;

  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      firstName:companydetail?companydetail.firstName:"",
      lastName: companydetail?companydetail.lastName:"",
      userName: companydetail?companydetail.userName:"",
      password: "",
      confirmPassword: "",
      email: companydetail?companydetail.email:"",
      mobile: companydetail?companydetail.mobile:"",
      companyName: companydetail?companydetail.companyName:"",
      companyEmail: companydetail?companydetail.companyEmail:"",
      companyContact: companydetail?companydetail.companyContact:"",
      companyAddress: companydetail?companydetail.companyAddress:"",
      // numberOfOffice: "",
      // numberOfDepartment: "",
      // numberOfDesignation: "",
    },

    validationSchema: CreateCompanySchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {

      
      try { 
        await createCompany(values, setShowToast, tokenId);
   
     if (createCompany) {
          resetForm();
        }
      } 
      catch (error) {
        console.log("Did Not Create Company", error);
      } 
      finally {
        setSubmitting(false);
        setTimeout(() => {
          navigate("/companylist")
        }, 3000);  
      }
    },
  });
 
  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };
  // Show & Hide Password
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="container-fluid dashboard_create_lead_main_container">
      <Link onClick={() => navigate(-1)}>
        <IoArrowBackCircleOutline
          className="_back_button"
          style={{ width: "60px" }}
        />
      </Link>
      <form onSubmit={handleSubmit}>
        <div>
          <p className="create_lead_section2_company_info mt-3">
            Company Details
          </p>
        </div>
        {/* Company Information */}
        <div className="row">
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyName">
              Company Name <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="companyName"
              maxLength={40}
              className="form-control create_lead_form_input"
              value={
                 values.companyName
              }
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyName"
              placeholder="Enter company name"
            />
            {touched.companyName && errors.companyName && (
              <small className="errorMessage">{errors.companyName}</small>
            )}
            <BsBuildingsFill className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyEmail">
              Company Email <span className="required_sign">*</span>
            </label>
            <input
              type="email"
              id="companyEmail"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={
                values.companyEmail
              }
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyEmail"
              placeholder="Enter Company Email"
            />
            {touched.companyEmail && errors.companyEmail && (
              <small className="errorMessage">{errors.companyEmail}</small>
            )}
            <MdEmail className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyContact">
              Company Contact <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="companyContact"
              maxlength="10"
              className="form-control create_lead_form_input"
              value={
                values.companyContact
              }
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyContact"
              placeholder="Enter company contact"
            />
            {touched.companyContact && errors.companyContact && (
              <small className="errorMessage">{errors.companyContact}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyAddress">
              Company Address <span className="required_sign">*</span>
            </label>
            <textarea
            maxLength={250}
              id="companyAddress"
              className="form-control create_lead_form_input"
              value={
                 values.companyAddress
              }
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyAddress"
              placeholder="Enter Company address"
            />

            {touched.companyAddress && errors.companyAddress && (
              <small className="errorMessage">{errors.companyAddress}</small>
            )}
            <FaTreeCity className="create_lead_input_icon" />
          </div>
          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="numberOfOffice">
              Number of Offices<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="numberOfOffice"
              className="form-control create_lead_form_input"
              value={values.numberOfOffice}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="numberOfOffice"
              placeholder="Enter Number of Offices"
            />
            {touched.numberOfOffice && errors.numberOfOffice && (
              <small className="errorMessage">{errors.numberOfOffice}</small>
            )}
            <FaTreeCity className="create_lead_input_icon" />
          </div>

          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="numberOfDepartment">
              Number of Department<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="numberOfDepartment"
              className="form-control create_lead_form_input"
              value={values.numberOfDepartment}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="numberOfDepartment"
              placeholder="Enter Number of Department"
            />
            {touched.numberOfDepartment && errors.numberOfDepartment && (
              <small className="errorMessage">{errors.numberOfDepartment}</small>
            )}
            <FaTreeCity className="create_lead_input_icon" />
          </div>

          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="numberOfDesignation">
              Number of Designation<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="numberOfDesignation"
              className="form-control create_lead_form_input"
              value={values.numberOfDesignation}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="numberOfDesignation"
              placeholder="Enter Number of Designation"
            />
            {touched.numberOfDesignation && errors.numberOfDesignation && (
              <small className="errorMessage">{errors.numberOfDesignation}</small>
            )}
            <FaTreeCity className="create_lead_input_icon" />
          </div> */}
        </div>
        {/* admin detail */}
        <div>
          <p className="create_lead_section2_company_info mt-3">
            Admin Details
          </p>
        </div>
        <div className="row">
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="firstName">
              Firstname <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="firstName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.firstName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="firstName"
              placeholder="Enter Firstname"
            />
            {touched.firstName && errors.firstName && (
              <small className="errorMessage">{errors.firstName}</small>
            )}
            <BsBuildingsFill className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="lastName">
              Lastname <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="lastName"
              maxLength={20}
              className="form-control create_lead_form_input"
              value={ values.lastName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="lastName"
              placeholder="Enter Lastname "
            />
            {touched.lastName && errors.lastName && (
              <small className="errorMessage">{errors.lastName}</small>
            )}
            <BsBuildingsFill className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="userName">
              username <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="userName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={ values.userName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="userName"
              placeholder="Enter username "
            />
            {touched.userName && errors.userName && (
              <small className="errorMessage">{errors.userName}</small>
            )}
            <BsBuildingsFill className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="email">
              Email <span className="required_sign">*</span>
            </label>
            <input
              type="email"
              id="email"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={ values.email}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="email"
              placeholder="Enter Email"
            />
            {touched.email && errors.email && (
              <small className="errorMessage">{errors.email}</small>
            )}
            <MdEmail className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="mobile">
              Mobile Number<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="mobile"
              maxLength={10}
              className="form-control create_lead_form_input"
              value={values.mobile}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="mobile"
              placeholder="Enter  Mobile Number"
            />
            {touched.mobile && errors.mobile && (
              <small className="errorMessage">{errors.mobile}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>

          {!companydetail && (
            <div className="form-group createLeadInput col-xl-4">
              <div className="mb-xl-4 position-relative">
                <label htmlFor="password">
                  Password <span className="required_sign">*</span>
                </label>
                <input
                  type={showPassword ? "password" : "text"}
                  minLength="6"
                  maxLength="14"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                  title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
                  className={`form-control signup_email_form_control`}
                  id="password"
                  placeholder="Create Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete=""
                  onFocus={handleFocus}
                />
                {touched.password && errors.password && (
                  <small className="errorMessageSignup">
                    {errors.password}
                  </small>
                )}
                {showPassword ? (
                  <p
                    className="signup_input_icons"
                    onClick={handleClickShowPassword}
                    style={{ cursor: "pointer" }}
                  >
                    🙈
                  </p>
                ) : (
                  <p
                    className="signup_input_icons"
                    onClick={handleClickShowPassword}
                    style={{ cursor: "pointer" }}
                  >
                    🐵
                  </p>
                )}
              </div>
            </div>
          )}
          {!companydetail && (
            <div className="form-group createLeadInput col-xl-4">
              <div className="mb-4 position-relative">
                <label htmlFor="confirmPassword">
                  Confirm Password
                  <span className="required_sign">*</span>
                </label>
                <input
                  type={showConfirmPassword ? "password" : "text"}
                  minLength={6}
                  maxLength={14}
                  className={`form-control signup_email_form_control `}
                  id="confirmPassword"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  autoComplete=""
                  onBlur={handleBlur}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <small className="errorMessageSignup">
                    {errors.confirmPassword}
                  </small>
                )}

                {showConfirmPassword ? (
                  <p
                    className="signup_input_icons"
                    onClick={handleClickShowConfirmPassword}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        height: "100%",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      🙈
                    </span>
                  </p>
                ) : (
                  <p
                    className="signup_input_icons"
                    onClick={handleClickShowConfirmPassword}
                    style={{ cursor: "pointer" }}
                  >
                    🐵
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        {/* Submit Button */}
        <div className="text-center">
          <button
            className="create_lead_form_submitBtn"
            type="submit"
            disabled={isSubmitting}
          >
            {companydetail ? "Update" : "Submit"}
          </button>
        </div>
      </form>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};
export default CreateCompany;
