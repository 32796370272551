import { useCallback, useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Goback from "./goback";
import Loader from "../pages/Loader";
import {
  getAllCompanies,
  getAllUsersMadeByAdmin,
  setmpin,
} from "../controller/fetchApi";
import CreatedUserTable from "./CreatedUserTable";
import CreatedCompanyTable from "./createCompanyTable";
import Setpin from "./setMpin";
import Toast from "./Toast";
import Pagination from "./pagination";

const CompanyList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pin = location.state?.pin;
  //set MPIN popup
  const [MPIN, setMPIN] = useState(false);

 useEffect(() => {
  if(pin)
    setMPIN(pin);  
 }, [])
 
  //setting MPIN
  const setpin = async (mPin) => {
    const setsuccessfully = await setmpin(tokenId, mPin);
    if (setsuccessfully?.status === 200) {
      //close pin popup
      setMPIN(false)
      setShowToast({ success: true, message: "MPIN set successfully.",optmessage:'setting MPIN' })
    }
  };

  // Start Toast -------
  const [showToast, setShowToast] = useState({ success: false, message: "",optmessage:"" });
  // Function to hide the toast after 3 seconds
  useEffect(() => {
     if (showToast.message) {
       const timer = setTimeout(() => {
         setShowToast({ success: false, message: "", optmessage: "" });
       }, 3000);
 
       return () => clearTimeout(timer);
     }
   }, [showToast])
  //End Toast-----------
  // TokenId
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  const userrole = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;

  // Set Contact Costumer Id in main Conntact.jsx
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(12);

  // const handlePagesize = (event) => {
    
  //   setPagesize(event.target.value);
  // };
  // Pagination Function ------
  // const [pageRangeStart, setPageRangeStart] = useState(0);
  // const totalPages = 1;
  // const pagesToShow = 6;
  // const handleNextPageClick = () => {
  //   const newPageNo = pageNo + 1;
  //   if (newPageNo < totalPages) {
  //     setPageNo(newPageNo);
  //     if (newPageNo >= pageRangeStart + pagesToShow) {
  //       setPageRangeStart(pageRangeStart + pagesToShow);
  //     }
  //   }
  // };
  // const handlePreviousPageClick = () => {
  //   const newPageNo = pageNo - 1;
  //   if (newPageNo >= 0) {
  //     setPageNo(newPageNo);
  //     if (newPageNo < pageRangeStart) {
  //       setPageRangeStart(pageRangeStart - pagesToShow);
  //     }
  //   }
  // };
  // const handlePageClick = (index) => {
  //   setPageNo(index);
  //   if (index >= pageRangeStart + pagesToShow) {
  //     setPageRangeStart(pageRangeStart + pagesToShow);
  //   } else if (index < pageRangeStart) {
  //     setPageRangeStart(pageRangeStart - pagesToShow);
  //   }
  // };
  //reset user data
  const [currdata, setcurrdata] = useState(false);
  //  Get All Users Api
  const [getAllCompanyData, setAllCompany] = useState(null);
 console.log('getAllCompanyData getAllCompanyData',getAllCompanyData)
  //set loader
  const [loading, setloading] = useState(true);

  const getAllCompany = useCallback(async () => {
    const result = await getAllCompanies(tokenId, pageSize, pageNo);
    setAllCompany(result?.data);
      setloading(false);
  }, [tokenId, pageSize, pageNo]);

  useEffect(() => {
    getAllCompany();
  }, [getAllCompany, currdata]);

  

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
      {loading ? (
        <Loader />
      ) : (
        <div className="dashboard_content_wrapper">
          <div >
            <Goback />
            {["SUPERADMIN"].includes(userrole) && (
              <>
                          <div className="dashboard_leads_btns_div px-2 mb-3">
                            <div class="actionbtn">
                              <button
                                class="default-btn"
                              >
                                <Link className="dashboard_leads_create_link" to="/create-company">
                                  <span>
                                    <MdAdd />
                                  </span>
                                  Create 
                                </Link>
                              </button>
                            </div>
                          </div>
</>
             
            )}
          </div>
          {/* Table Div */}
          <div class="responsivetable">
            <table class="table mytable">
            <CreatedCompanyTable
              setcurrdata={setcurrdata}
              tblHead={{
                firstHead: "Sr. No",
                secondHead: "Company Name",
                thirdHead: "Company Email",
                fourthHead: "Contact Number",
                fifthHead: "Address",
                sixthHead: "Show",
                sevenHead:"Update",
                eightHead:"Action"
              }}
              data={getAllCompanyData?.content}
              getAllCompany={getAllCompany}
              redirectLink="/role&permission"
            />
            </table>
          </div>
          <Pagination
          maxData={getAllCompanyData?.totalElements}
          totalPages={getAllCompanyData?.totalPages}
          totalElements={getAllCompanyData?.totalElements}
          showingElements={getAllCompanyData?.currentPageElements}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageSize={pageSize}
          setPagesize={setPagesize}
        />
          {/* <div className="dashboard_leads_table_div">
            <CreatedCompanyTable
              setcurrdata={setcurrdata}
              tblHead={{
                firstHead: "Sr. No",
                secondHead: "Company Name",
                thirdHead: "Company Email",
                fourthHead: "Contact Number",
                fifthHead: "Address",
                sixthHead: "Action",
              }}
              data={getAllCompanyData?.content}
              getAllCompany={getAllCompany}
              redirectLink="/role&permission"
            />
          </div> */}
         
          {/* Pagination Div */}
          {/* <div className="dashboard_leads_pagination_div">
            <nav aria-label="..." style={{display:'flex'}}>
              <div>
                <span className="page_size_control">Display</span>
              <input
                  className="company_page_size"
                  type="number"
                  defaultValue={12}
                  min={1}
                  step={4}
                  onChange={handlePagesize}
                />
              </div>
              <ul
                className="pagination"
                style={{ display: "flex", alignItems: "center" }}
              >
             
                <li className="page-item dashboard_leads_pagination_pageItem">
                  <a
                    className="page-link"
                    href="#!"
                    onClick={handlePreviousPageClick}
                  >
                    <IoIosArrowBack />
                  </a>
                </li>

               
                {Array.from({ length: pagesToShow }, (_, index) => {
                  const pageIndex = pageRangeStart + index;
                  return (
                    pageIndex < totalPages && (
                      <li
                        key={pageIndex}
                        className={`page-item ${
                          pageIndex === pageNo ? "active" : ""
                        } dashboard_leads_pagination_pageItem`}
                      >
                        <a
                          className="page-link"
                          href="#!"
                          onClick={() => handlePageClick(pageIndex)}
                        >
                          {pageIndex + 1 < 10
                            ? `0${pageIndex + 1}`
                            : pageIndex + 1}
                        </a>
                      </li>
                    )
                  );
                })}

               
                <li className="page-item dashboard_leads_pagination_pageItem">
                  <a
                    className="page-link"
                    href="#!"
                    onClick={handleNextPageClick}
                  >
                    <IoIosArrowForward className="btn_IoIosArrowForward" />
                  </a>
                </li>
              </ul>
            </nav>
          </div> */}
         
      
        </div>
      )}
       {MPIN && (
        <div
          className="set_pin"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Setpin setpin={setpin} />
        </div>
      )}
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CompanyList;
