import React, { useState, useEffect, Suspense } from "react";

//Import React Router Dom
import { Routes, Route } from "react-router-dom";
import Main from "../pages/Main";
// import LandingPage from "../pages/LandingPage";
import Productlist from "../pages/product&services";
import ResetPassword from "../pages/ResetPassword";
// const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));
// Pages
// import Signup from "../pages/Signup";
import Login from "../pages/Login";
// const Login = React.lazy(() => import("../pages/Login"));
import ForgotPassword from "../pages/ForgotPassword";
// const ForgotPassword = React.lazy(() => import("../pages/ForgotPassword"));
import OtpVerification from "../pages/OtpVerification";
// const OtpVerification = React.lazy(() => import("../pages/OtpVerification"));
// import Main from "../pages/Main";
// const Main = React.lazy(() => import("../pages/Main"));
import SuperAdmin from "../pages/SuperAdmin";
// const SuperAdmin = React.lazy(() => import("../pages/SuperAdmin"));
import Dashboard from "../pages/dashboardPages/Dashboard";
// const Dashboard = React.lazy(() => import("../pages/dashboardPages/Dashboard"));
import Leads from "../pages/dashboardPages/Leads";
// const Leads = React.lazy(() => import("../pages/dashboardPages/Leads"));
import CreateLead from "../pages/dashboardPages/CreateLead";
// const CreateLead = React.lazy(() =>
//   import("../pages/dashboardPages/CreateLead")
// );
import LeadCostumerDetails from "../pages/dashboardPages/LeadCostumerDetails";
// const LeadCostumerDetails = React.lazy(() =>
//   import("../pages/dashboardPages/LeadCostumerDetails")
// );
import Contact from "../pages/dashboardPages/Contact";
// const Contact = React.lazy(() => import("../pages/dashboardPages/Contact"));
import CreateContact from "../pages/dashboardPages/CreateContact";
// const CreateContact = React.lazy(() =>
//   import("../pages/dashboardPages/CreateContact")
// );
import ContactCostumerDetails from "../pages/dashboardPages/ContactCostumerDetails";
// const ContactCostumerDetails = React.lazy(() =>
//   import("../pages/dashboardPages/ContactCostumerDetails")
// );
import Accounts from "../pages/dashboardPages/Accounts";
// const Accounts = React.lazy(() => import("../pages/dashboardPages/Accounts"));
import CreateAccount from "../pages/dashboardPages/CreateAccount";
// const CreateAccount = React.lazy(() =>
//   import("../pages/dashboardPages/CreateAccount")
// );
import AccountCostumerDetails from "../pages/dashboardPages/AccountCostumerDetails";
// const AccountCostumerDetails = React.lazy(() =>
//   import("../pages/dashboardPages/AccountCostumerDetails")
// );
import Task from "../pages/dashboardPages/Task";
// const Task = React.lazy(() => import("../pages/dashboardPages/Task"));
import CreateTask from "../pages/dashboardPages/CreateTask";
// const CreateTask = React.lazy(() =>
//   import("../pages/dashboardPages/CreateTask")
// );
import TaskDetails from "../pages/dashboardPages/TaskDetails";
// const TaskDetails = React.lazy(() =>
//   import("../pages/dashboardPages/TaskDetails")
// );
import Meetings from "../pages/dashboardPages/Meetings";
// const Meetings = React.lazy(() => import("../pages/dashboardPages/Meetings"));
import CreateMeeting from "../pages/dashboardPages/CreateMeeting";
// const CreateMeeting = React.lazy(() =>
//   import("../pages/dashboardPages/CreateMeeting")
// );
import MeetingsViewDetails from "../pages/dashboardPages/MeetingsViewDetails";
// const MeetingsViewDetails = React.lazy(() =>
//   import("../pages/dashboardPages/MeetingsViewDetails")
// );
import Calls from "../pages/dashboardPages/Calls";
// const Calls = React.lazy(() => import("../pages/dashboardPages/Calls"));
import ScheduleCall from "../pages/dashboardPages/ScheduleCall";
// const ScheduleCall = React.lazy(() =>
//   import("../pages/dashboardPages/ScheduleCall")
// );
import LogCall from "../pages/dashboardPages/LogCall";
// const LogCall = React.lazy(() => import("../pages/dashboardPages/LogCall"));
import CallLogs from "../pages/dashboardPages/CallLogs";
// const CallLogs = React.lazy(() => import("../pages/dashboardPages/CallLogs"));
import CallViewDetails from "../pages/dashboardPages/CallViewDetails";
// const CallViewDetails = React.lazy(() =>
//   import("../pages/dashboardPages/CallViewDetails")
// );
import LogCallViewDetails from "../pages/dashboardPages/LogCallViewDetails";
// const LogCallViewDetails = React.lazy(() =>
//   import("../pages/dashboardPages/LogCallViewDetails")
// );
import Deals from "../pages/dashboardPages/Deals";
// const Deals = React.lazy(() => import("../pages/dashboardPages/Deals"));
import CreateDeal from "../pages/dashboardPages/CreateDeal";
// const CreateDeal = React.lazy(() =>
//   import("../pages/dashboardPages/CreateDeal")
// );
import Reports from "../pages/dashboardPages/Reports";
// const Reports = React.lazy(() => import("../pages/dashboardPages/Reports"));
import UpdateLead from "../pages/dashboardPages/UpdateLead";
// const UpdateLead = React.lazy(() =>
//   import("../pages/dashboardPages/UpdateLead")
// );
import DealsCostumerDetails from "../pages/dashboardPages/DealsCostumerDetails";
// const DealsCostumerDetails = React.lazy(() =>
//   import("../pages/dashboardPages/DealsCostumerDetails")
// );
import UpdateProfile from "../pages/UpdateProfile";
// const UpdateProfile = React.lazy(() => import("../pages/UpdateProfile"));
import CreatedUser from "../pages/CreatedUser";
// const CreatedUser = React.lazy(() => import("../pages/CreatedUser"));
import RoleAndPermission from "../pages/RoleAndPermission";
// const RoleAndPermission = React.lazy(() =>
//   import("../pages/RoleAndPermission")
// );
import LandingPage from "../pages/LandingPage";
// const LandingPage = React.lazy(() => import("../pages/LandingPage"));

//Import Validation Route
import ValidateRoute from "./ValidateRoute";
// const ValidateRoute = React.lazy(() => import("./ValidateRoute"));
import ErrorPage from "../pages/ErrorPage";
// const ErrorPage = React.lazy(() => import("../pages/ErrorPage"));
import CompanyList from "../components/companyList";
// const CompanyList = React.lazy(() => import("../components/companyList"));
import CreateCompany from "../components/createdCompany";
// const CreateCompany = React.lazy(() => import("../components/createdCompany"));
import CompanyDetails from "../components/companyDetails";
// const CompanyDetails = React.lazy(() => import("../components/companyDetails"));
import UpdateCompanyDetails from "../components/updatecompanydetail";
// const UpdateCompanyDetails = React.lazy(() =>
//   import("../components/updatecompanydetail")
// );
import UserActivityLog from "../pages/userActivityLog";
// const UserActivityLog = React.lazy(() => import("../pages/userActivityLog"));
// const Dashboard = React.lazy(() => import("../pages/dashboardPages/Dashboard"));
// const CreatedUser = React.lazy(() => import("../pages/CreatedUser"));
const AllRoutes = () => {
  const [loading, setLoading] = useState(true);
  const [isSidebar, setIsSidebar] = useState(window.innerWidth > 425);
  useEffect(() => {
    const handleResize = () => {
      setIsSidebar(window.innerWidth > 425);
    };
    window.addEventListener("resize", handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Routes>
      {/* Dashboard Routes */}

      <Route
        element={
          <Main
            isSidebar={isSidebar}
            setIsSidebar={setIsSidebar}
            loading={loading}
            setLoading={setLoading}
          />
        }
      >
        <Route path="/product&services" element={<Productlist/>}/>
        <Route
          path="/companylist"
          element={
            <ValidateRoute requiredRoles={["SUPERADMIN"]}>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CompanyList />
              </Suspense> */}
              <CompanyList />
            </ValidateRoute>
          }
        />
        <Route
          path="/companydetails"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CompanyDetails />
              </Suspense> */}
               <CompanyDetails />
            </ValidateRoute>
          }
        />
        <Route
          path="/updatecompanydetails"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <UpdateCompanyDetails />
              </Suspense> */}
              <UpdateCompanyDetails />
            </ValidateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ValidateRoute
              requiredRoles={[
                "ADMIN",
                "PROJECTMANAGER",
                "SUBADMIN",
                "SALESEXECUTIVE",
                "CORPORATESALESMANAGER",
                "ONBOARDINGMANAGER",
                "LEADVERIFICATIONMANAGER",
                "KEYACCOUNTMANAGER",
                "FREELANCERSALESMANAGER",
              ]}
            >
              {/* <Suspense fallback={<div>Loading...</div>}>
                <Dashboard />
              </Suspense> */}
               <Dashboard />
            </ValidateRoute>
          }
        />
        <Route
          path="/created-users"
          element={
            <ValidateRoute requiredRoles={["ADMIN", "SUBADMIN"]}>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CreatedUser />
              </Suspense> */}
              <CreatedUser />
            </ValidateRoute>
          }
        />
        <Route
          path="/role&permission"
          element={
            <ValidateRoute requiredRoles={["SUBADMIN", "ADMIN"]}>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <RoleAndPermission />
              </Suspense> */}
               <RoleAndPermission />
            </ValidateRoute>
          }
        />
        <Route
          path="/leads"
          element={
            <ValidateRoute requiredModule="Leads">
              {/* <Suspense fallback={<div>Loading...</div>}>
                <Leads loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <Leads loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/create-lead"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CreateLead loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <CreateLead loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/update-lead"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <UpdateLead loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <UpdateLead loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/lead-details"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <LeadCostumerDetails
                  loading={loading}
                  setLoading={setLoading}
                />
              </Suspense> */}
               <LeadCostumerDetails
                  loading={loading}
                  setLoading={setLoading}
                />
            </ValidateRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <ValidateRoute requiredModule="Contacts">
              {/* <Suspense fallback={<div>Loading...</div>}>
                <Contact loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <Contact loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/create-contact"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CreateContact loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <CreateContact loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/create-company"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CreateCompany loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <CreateCompany loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/contact-details"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <ContactCostumerDetails
                  loading={loading}
                  setLoading={setLoading}
                />
              </Suspense> */}
               <ContactCostumerDetails
                  loading={loading}
                  setLoading={setLoading}
                />
            </ValidateRoute>
          }
        />
        <Route
          path="/accounts"
          element={
            <ValidateRoute requiredModule="Accounts">
              {/* <Suspense fallback={<div>Loading...</div>}>
                <Accounts loading={loading} setLoading={setLoading} />
              </Suspense> */}
                <Accounts loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/create-account"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CreateAccount loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <CreateAccount loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/account-details"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <AccountCostumerDetails
                  loading={loading}
                  setLoading={setLoading}
                />
              </Suspense> */}
               <AccountCostumerDetails
                  loading={loading}
                  setLoading={setLoading}
                />
            </ValidateRoute>
          }
        /> 
        <Route
          path="/tasks"
          element={
            <ValidateRoute requiredModule="Tasks">
              {/* <Suspense fallback={<div>Loading...</div>}>
                <Task loading={loading} setLoading={setLoading} />
              </Suspense> */}
                 <Task loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/create-task"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CreateTask loading={loading} setLoading={setLoading} />
              </Suspense> */}
                 <CreateTask loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/task-details"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <TaskDetails loading={loading} setLoading={setLoading} />
              </Suspense> */}
                <TaskDetails loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/meetings"
          element={
            <ValidateRoute requiredModule="Meetings">
              {/* <Suspense fallback={<div>Loading...</div>}>
                <Meetings loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <Meetings loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/create-meeting"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CreateMeeting loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <CreateMeeting loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/meetings-details"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <MeetingsViewDetails
                  loading={loading}
                  setLoading={setLoading}
                />
              </Suspense> */}
              <MeetingsViewDetails
                  loading={loading}
                  setLoading={setLoading}
                />
            </ValidateRoute>
          }
        />
        <Route
          path="/call-schedule"
          element={
            <ValidateRoute requiredModule="Calls">
              {/* <Suspense fallback={<div>Loading...</div>}>
                <Calls loading={loading} setLoading={setLoading} />
              </Suspense> */}
                <Calls loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/schedule-call"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <ScheduleCall loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <ScheduleCall loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/log-call"
          element={
            <ValidateRoute requiredModule="Calls">
              {/* <Suspense fallback={<div>Loading...</div>}>
                <LogCall loading={loading} setLoading={setLoading} />
              </Suspense> */}
                <LogCall loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/call-logs"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CallLogs loading={loading} setLoading={setLoading} />
              </Suspense> */}
                  <CallLogs loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/schedule-call-details"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CallViewDetails loading={loading} setLoading={setLoading} />
              </Suspense> */}
                 <CallViewDetails loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/log-call-details"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <LogCallViewDetails loading={loading} setLoading={setLoading} />
              </Suspense> */}
                 <LogCallViewDetails loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/deals"
          element={
            <ValidateRoute requiredModule="Deals">
              {/* <Suspense fallback={<div>Loading...</div>}>
                <Deals loading={loading} setLoading={setLoading} />
              </Suspense> */}
                   <Deals loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/create-deal"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <CreateDeal loading={loading} setLoading={setLoading} />
              </Suspense> */}
                 <CreateDeal loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/deal-details"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <DealsCostumerDetails
                  loading={loading}
                  setLoading={setLoading}
                />
              </Suspense> */}
              <DealsCostumerDetails
                  loading={loading}
                  setLoading={setLoading}
                />
            </ValidateRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <Reports loading={loading} setLoading={setLoading} />
              </Suspense> */}
               <Reports loading={loading} setLoading={setLoading} />
            </ValidateRoute>
          }
        />
        <Route
          path="/userLogs"
          element={
            <ValidateRoute>
              {/* <Suspense fallback={<div>Loading...</div>}>
                <UserActivityLog />
              </Suspense> */}
                <UserActivityLog />
            </ValidateRoute>
          }
        />
      </Route>
      <Route
        path="/otpverification"
        element={
          <ValidateRoute>
            {/* <Suspense fallback={<div>Loading...</div>}>
              <OtpVerification />
            </Suspense> */}
             <OtpVerification />
          </ValidateRoute>
        }
      />
      <Route
        path="/error-page"
        element={
          <ValidateRoute>
            {/* <Suspense fallback={<div>Loading...</div>}>
              <ErrorPage />
            </Suspense> */}
              <ErrorPage />
          </ValidateRoute>
        }
      />
      {/* <Route element={<Landingerror/>}>
     
      </Route> */}
      <Route
        path="/"
        element={
          // <Suspense fallback={<div>Loading...</div>}>
            <LandingPage />
          // </Suspense>
        }
      />
      {/* <Route path="/signup" element={<Signup />} /> */}
      <Route
        path="/login"
        element={  <Login />
          // <Suspense fallback={<div>Loading...</div>}>
          //   <Login />
          // </Suspense>
        }
      />
      <Route
        path="/forgotpassword"
        element={ <ForgotPassword />
          // <Suspense fallback={<div>Loading...</div>}>
          //   <ForgotPassword />
          // </Suspense>
        }
      />
      <Route
        path="/resetpassword"
        element={   <ResetPassword />
          // <Suspense fallback={<div>Loading...</div>}>
          //   <ResetPassword />
          // </Suspense>
        }
      />
      <Route
        path="/update-profile"
        element={ <UpdateProfile />
          // <Suspense fallback={<div>Loading...</div>}>
          //   <UpdateProfile />
          // </Suspense>
        }
      />
      <Route
        path="*"
        element={ <ErrorPage />
          // <Suspense fallback={<div>Loading...</div>}>
          //   <ErrorPage />
          // </Suspense>
        }
      />
    </Routes>
  );
};

export default AllRoutes;
