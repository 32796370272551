import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

// CSS
// import "../styles/component_css/leadsLeftSection.css";
// React Icon
const FilterSidebar = ({ filterData, setFilterData, setFilter }) => {
  const [filterField, setfilterField] = useState("");
  useEffect(() => {
    document.addEventListener("click", () => {
      setFilter(false);
    });

    return () => {
      document.removeEventListener("click", () => {
        setFilter(false);
      });
    };
  });
  // Get By Default Data From Local Storage
  const savedFilter = JSON.parse(localStorage.getItem("filterData") || "{}");
  //const savedFilter = filterData;

  // Form Handle & Validations
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      filterBy: "",
      filterValue: "",
      dateRange: "",
      customStartDate: "",
      customEndDate: "",
      leadType: filterData?.leadType,
    },
    onSubmit: async (values, { resetForm }) => {
      setFilterData(values);
    },
  });

  return (
    <div
      className="filter-sidebar"
      style={{ width: "100%",height:"100%" }}
      onClick={(Event) => Event.stopPropagation()}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          gap: "15px",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <div
          className="filter-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            rowGap: "15px",
            width: "100%",
          }}
        >
          {/* ---------------------- */}
          <label
            htmlFor="leadType"
            style={{
              marginBottom: "-10px",
              fontWeight: "500",
              fontSize: "15px",
            }}
          >
            Lead type:
          </label>
          <div className="d-flex align-items-center w-100">
            <select
              className="Filter_fontSize"
              id="leadType"
              name="leadType"
              value={values.leadType}
              onChange={handleChange}
            >
              <option value="" hidden className="Filter_fontSize">
                Lead type
              </option>
              <option value="verified" className="Filter_fontSize">
                Verified
              </option>
              <option value="unverified" className="Filter_fontSize">
                Unverified
              </option>
              <option value="rejected" className="Filter_fontSize">
                Rejected
              </option>
            </select>
          </div>
        </div>
        {/* System Defined Filter */}
        {/* -------------------------------------- */}
        {/* <div className="filter-container_radio">
            <div className="form-check">
              <input
                name="verified"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="touchedFilter"
                value={values.verified}
                // onChange={handleChange}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                //checked={true ? values.verified : "verified" || ""}
                checked={values.verified}
              />
              <label className="form-check-label" htmlFor="touchedFilter">
                Verified
              </label>
            </div>
            <div className="form-check">
              <input
                name="unverified"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="untouchedFilter"
                value={values.unverified}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                //checked={true ? values.unverified : "unverified" || ""}
                checked={values.unverified}
              />
              <label className="form-check-label" htmlFor="untouchedFilter">
                Unverified
              </label>
            </div>
            <div className="form-check">
              <input
                name="rejected"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="rejectedFilter"
                value={values.rejected}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                checked={values.rejected}
              />
              <label className="form-check-label" htmlFor="rejectedFilter">
                Rejected
              </label>
            </div>
          </div> */}
        {/* <details className="filter-container_detail">
          <summary>Filter By</summary>
          <div className="filter-container_radio">
            <div className="form-check">
              <input
                name="verified"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="touchedFilter"
                value={values.verified}
                // onChange={handleChange}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                checked={true ? values.verified : "verified" || ""}
              />
              <label className="form-check-label" htmlFor="touchedFilter">
                Verified
              </label>
            </div>
            <div className="form-check">
              <input
                name="unverified"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="untouchedFilter"
                value={values.unverified}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                checked={true ? values.unverified : "unverified" || ""}
              />
              <label className="form-check-label" htmlFor="untouchedFilter">
                Unverified
              </label>
            </div>
            <div className="form-check">
              <input
                name="rejected"
                type="radio"
                className="form-check-input lead_filter_radio_btn"
                id="rejectedFilter"
                value={values.rejected}
                onChange={handleCheckboxChange}
                onBlur={handleBlur}
                checked={values.rejected}
              />
              <label className="form-check-label" htmlFor="rejectedFilter">
                Rejected
              </label>
            </div>
          </div>
        </details> */}
        {/* ----------------------------------- */}
        {/* <div className="filter-container">
          <h4>System Defined Filter</h4>
          <div className="form-check">
            <input
              name="verified"
              type="radio"
              className="form-check-input lead_filter_radio_btn"
              id="touchedFilter"
              value={values.verified}
              // onChange={handleChange}
              onChange={handleCheckboxChange}
              onBlur={handleBlur}
              checked={true ? values.verified : "verified" || ""}
            />
            <label className="form-check-label" htmlFor="touchedFilter">
              Verified
            </label>
          </div>
          <div className="form-check">
            <input
              name="unverified"
              type="radio"
              className="form-check-input lead_filter_radio_btn"
              id="untouchedFilter"
              value={values.unverified}
              onChange={handleCheckboxChange}
              onBlur={handleBlur}
              checked={true ? values.unverified : "unverified" || ""}
            />
            <label className="form-check-label" htmlFor="untouchedFilter">
              Unverified
            </label>
          </div>
          <div className="form-check">
            <input
              name="rejected"
              type="radio"
              className="form-check-input lead_filter_radio_btn"
              id="rejectedFilter"
              value={values.rejected}
              onChange={handleCheckboxChange}
              onBlur={handleBlur}
              checked={values.rejected}
            />
            <label className="form-check-label" htmlFor="rejectedFilter">
              Rejected
            </label>
          </div>
        </div> */}
        {/* Filter By Fields */}
        <div
          className="filter-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            rowGap: "15px",
            width: "100%",
          }}
        >
          {/* ---------------------- */}
          <label
            htmlFor="filterBy"
            style={{
              marginBottom: "-10px",
              fontWeight: "500",
              fontSize: "15px",
            }}
          >
            Filter by field:
          </label>
          <div className="d-flex align-items-center w-100">
            <select
              className="Filter_fontSize"
              id="filterBy"
              name="filterBy"
              onChange={({ target: { value } }) => {
                setfilterField(value);
                setFieldValue("filterBy", value);
                setFieldValue([value], "");
              }}
              value={values.filterBy}
            >
              <option hidden selected className="Filter_fontSize">
                Filter by
              </option>
              <option value="firstName" className="Filter_fontSize">
                Name
              </option>
              <option value="city" className="Filter_fontSize">
                City Name
              </option>
              <option value="companyName" className="Filter_fontSize">
                Company Name
              </option>
              {/* <option value="date">Date</option> */}
              <option value="leadOwner" className="Filter_fontSize">
                LeadOwner Name
              </option>
              <option value="leadSource" className="Filter_fontSize">
                Lead Source
              </option>
              <option value="mobile" className="Filter_fontSize">
                Mobile
              </option>
              <option value="" className="Filter_fontSize">
                None
              </option>
            </select>
          </div>

          {/* ------------------------------- */}
          {values.filterBy && (
            <div>
              <input
                id="filterfrom"
                type={values.hasOwnProperty("date") ? "date" : "text"}
                // name={`${filterField}`}
                name="filterValue"
                placeholder={
                  filterField ? `Enter ${filterField}` : "Enter filter"
                }
                className="filters leads_filter_input_field"
                value={values.filterValue}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          )}
          {/* ------------------------------- */}
          {/* date range */}
          <label
            htmlFor="dateRange"
            style={{
              marginBottom: "-10px",
              fontWeight: "500",
              fontSize: "15px",
            }}
          >
            Filter by date:
          </label>
          <div style={{ width: "100%" }}>
            <select
              id="dateRange"
              className="customrangefilter Filter_fontSize"
              onChange={handleChange}
              name="dateRange"
              value={values?.dateRange}
            >
              <option hidden selected>
                By Date
              </option>
              <option value="today" className="Filter_fontSize">
                Today
              </option>
              <option value="yesterday" className="Filter_fontSize">
                Yesterday
              </option>
              <option value="weekly" className="Filter_fontSize">
                Last Week
              </option>
              <option value="monthly" className="Filter_fontSize">
                Last Month
              </option>
              <option value="yearly" className="Filter_fontSize">
                Last Year
              </option>
              <option value="custom" className="Filter_fontSize">
                Custom
              </option>
              <option value="" className="Filter_fontSize">
                None
              </option>
            </select>

            {values?.dateRange === "custom" && (
              <div>
                <div>
                  <label htmlFor="customStartDate" className="Filter_fontSize">
                    From:
                  </label>
                  <input
                    type="date"
                    maxLength={8}
                    name="customStartDate"
                    id="customStartDate"
                    value={values.customStartDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div>
                  <label htmlFor="customEndDate" className="Filter_fontSize">
                    Till:
                  </label>
                  <input
                    type="date"
                    maxLength={8}
                    name="customEndDate"
                    id="customEndDate"
                    value={values.customEndDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            )}
          </div>

          {/* **************************************************** */}
          {/* <div>
            <label htmlFor="cityName"></label>
            <input
              type="text"
              name="cityName"
              placeholder="Enter City"
              className="filters leads_filter_input_field"
              value={values.cityName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div>
            <label htmlFor="companyName"></label>
            <input
              type="text"
              name="companyName"
              placeholder="Company name"
              className="filters leads_filter_input_field"
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div>
            <label htmlFor="date"></label>
            <input
              type="date"
              name="date"
              placeholder="Select date"
              className="filters leads_filter_input_field"
              value={values.date}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div>
            <label htmlFor="leadOwner"></label>
            <input
              type="text"
              name="leadOwnerName"
              placeholder="Enter Lead Owner"
              className="filters leads_filter_input_field"
              value={values.leadOwnerName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div> */}
        </div>
        {/* Btns */}
        <div className="applybtn">
          <button
            className="filter_save"
          
            //className="btn btn-primary filterBtn "
            type="submit"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

export default FilterSidebar;
