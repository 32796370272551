import React, { useState, useEffect } from "react";
import leadIdContext from "../LeadIdContext";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
//Import CSS
// import "../../styles/dashboardCss/leads.css";
//Import Components
import FilterSidebar from "../../components/LeadsLeftSection";
import LeadsRightSection from "../../components/LeadsRightSection";
import filter_icon from "../../images/filter_icon.png";

const initialFilterState = {
  cityName: "",
  companyName: "",
  date: "",
  leadOwnerName: "",
  verified: "",
  unverified: "",
  rejected: "",
  dateRange: "",
  customStartDate: "",
  customEndDate: "",
};

// Function to load filter state from localStorage
const loadFilterState = () => {
  const savedFilter = localStorage.getItem("filterData");
  return savedFilter ? JSON.parse(savedFilter) : initialFilterState;
};

const Leads = () => {
  const location = useLocation();
  // const filterType = async ()=> await location.state?.lead;
  const [leadCostumerId, setLeadCostumerId] = useState([]);
  const [filter, setFilter] = useState(false);
  const filterType = location.state?.lead;
  const leadStatus = location?.state?.leadstatus;

  const [filterData, setFilterData] = useState({
    filterBy: leadStatus ? "leadStatus" : "",
    filterValue: leadStatus ? leadStatus : "",
    dateRange: "",
    customStartDate: "",
    customEndDate: "",
    leadType: filterType || "verified",
  });

  useEffect(() => {
    if (leadStatus) {
      setFilterData({
        filterBy: leadStatus ? "leadStatus" : "",
        filterValue: leadStatus ? leadStatus : "",
        dateRange: "",
        customStartDate: "",
        customEndDate: "",
        leadType: leadStatus ? "verified" : "unverified",
      });
    } else {
      setFilterData({
        filterBy: leadStatus ? "leadStatus" : "",
        filterValue: leadStatus ? leadStatus : "",
        dateRange: "",
        customStartDate: "",
        customEndDate: "",
        leadType: filterType || "verified",
      });
    }
  }, [leadStatus]);
  useEffect(() => {
    if (filterType) {
      setFilterData({
        filterBy: "",
        filterValue: "",
        dateRange: "",
        customStartDate: "",
        customEndDate: "",
        leadType: "verified",
      });
    }
  }, []);

  const navigate = useNavigate();
  // Save filter state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("filterData", JSON.stringify(filterData));
  }, [filterData]);

  return (
    <div className="container-fluid dashboard_leads_main_container">
      <div className="row dashboard_filter_sidebar_row position-relative">
        {/* In Leads Page Left Filter Side Bar Section */}
        <div
          className=" col-xl-2 col-md-3 filter_sidebar_col position-absolute"
          style={{ zIndex: "111", top: "80px" }}
        >
         
          {filter && (
            <div
              className="filter_sidebar_mainDiv"
              onClick={(Event) => Event.stopPropagation()}
            >
              <FilterSidebar
                setFilter={setFilter}
                filterData={filterData}
                setFilterData={setFilterData}
              />
            </div>
          )}
        </div>
        {/* Right Section Who's Section Have Tables Form Data */}
        <div className="col-xl-12 col-md-9 p-0" style={{ maxHeight: "100%" }}>
          <leadIdContext.Provider value={{ leadCostumerId, setLeadCostumerId }}>
            <LeadsRightSection
              leadStatus={leadStatus}
              setFilter={setFilter}
              leadCostumerId={leadCostumerId}
              setLeadCostumerId={setLeadCostumerId}
              filterData={filterData}
            />
          </leadIdContext.Provider>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Leads;
