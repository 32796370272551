import React, { useState } from "react";
// css
// import "../styles/signup.page.css";
// Formik
import { useFormik } from "formik";
import { signupFormSchema } from "../schema/FormValidation";
// React Icons
import { FcBusinessman } from "react-icons/fc";
import { FcFeedback } from "react-icons/fc";
import { FcCellPhone } from "react-icons/fc";
import { FcManager } from "react-icons/fc";
// Redux Toolkit
import { useDispatch } from "react-redux";
import { setUserData } from "../app/slices";

// React Router Dom
import { Link, useNavigate } from "react-router-dom";

// Import Api Function
import { countryCode, signupUser } from "../controller/fetchApi";
import { FaRegEyeSlash } from "react-icons/fa";
import { BiShow } from "react-icons/bi";

// Main Component Function
const Signup = () => {
  const dispatch = useDispatch();
  // TokenId
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  const navigate = useNavigate();
  // Toast
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });

  // Function to hide the toast after 3 seconds
  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ success: false, message: "" });
    }, 3000);
  };
  if (showToast.message) {
    hideToast();
  }
  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
    },

    validationSchema: signupFormSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const data = await countryCode();
      const response = await signupUser(
        values,
        setSubmitting,
        setShowToast,
        tokenId
      );

      if (response) {
        navigate("/otpverification");
        resetForm();
        dispatch(
          setUserData({
            email: values.email,
            name: `${values.firstName} ${values.lastName}`,
          })
        );
      }
    },
  });

  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };
  // Show & Hide Password
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <>
      <div className="container-fluid signup_body_div">
        <div className="row">
          {/* Left Main Div Start*/}
          <div className="col-xl-12 col-md-12 col-sm-12">
            <div className="container signup_left_container">
              <div id="signup_left_mainDiv">
                <form onSubmit={handleSubmit}>
                  <p className="usertitle ">Create User</p>
                  <div className="formGroup">
                    {/* Full Name */}
                    <div className="signup_fullname_div mb-3">
                      {/* first name */}
                      <div
                        className="position-relative"
                        // className="mb-xl-4 position-relative"
                      >
                        <div className="form-group">
                          <label
                            htmlFor="signup_firstName"
                            className="form-label signup_div_name"
                          >
                            First Name <span className="required_sign">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            maxlength="20"
                            className={`form-control signup_name_form_control`}
                            id="signup_firstName"
                            placeholder="Enter First Name"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                          />
                        </div>
                        {touched.firstName && errors.firstName && (
                          <small className="errorMessageSignup">
                            {errors.firstName}
                          </small>
                        )}
                      </div>
                     
                      {/* last name */}
                      <div
                        className="position-relative"
                        //  className="mb-xl-4 position-relative"
                      >
                        <div className="form-group">
                          <label
                            htmlFor="signup_lastName"
                            className="form-label signup_div_name"
                          >
                            Last Name <span className="required_sign">*</span>
                          </label>
                          <input
                            type="text"
                            pattern="[a-zA-Z]{1,30}"
                            maxlength="20"
                            className={`form-control signup_name_form_control`}
                            id="signup_lastName"
                            placeholder="Enter Last Name"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                          />
                          {touched.lastName && errors.lastName && (
                            <small className="errorMessageSignup ">
                              {errors.lastName}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  
                    {/* UserName */}
                    <div className="mb-3">
                      <div className=" position-relative">
                        <div className="form-group">
                          <label
                            htmlFor="signup_userName"
                            className="form-label signup_div_input"
                          >
                            Username <span className="required_sign">*</span>
                          </label>
                          <input
                            type="text"
                            maxlength="30"
                            className={`form-control signup_email_form_control userName_input_signup`}
                            id="signup_userName"
                            name="userName"
                            placeholder="Enter User Name"
                            value={values.userName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                          />
                        </div>
                        {touched.userName && errors.userName && (
                          <small className="errorMessageSignup">
                            {errors.userName}
                          </small>
                        )}
                      </div>
                    </div>
                   
                    {/* Email */}
                    <div className="mb-3">
                      <div className=" position-relative">
                        <div className="form-group">
                          <label
                            htmlFor="signup_email"
                            className="form-label signup_div_input"
                          >
                            {" "}
                            Email
                            <span className="required_sign">*</span>
                          </label>
                          <input
                            type="email"
                            maxLength="64"
                            className={`form-control signup_email_form_control`}
                            id="signup_email"
                            name="email"
                            placeholder="Enter Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                          />
                          {touched.email && errors.email && (
                            <small className="errorMessageSignup">
                              {errors.email}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  
                    {/* Phone */}
                    <div className="mb-3">
                      <div className="position-relative">
                        <div className="form-group">
                          <label
                            htmlFor="signup_phone"
                            className="form-label signup_div_input"
                          >
                            Phone Number{" "}
                            <span className="required_sign">*</span>
                          </label>
                          <input
                            type="text"
                            maxLength="10"
                            className={`form-control signup_email_form_control`}
                            id="signup_phone"
                            placeholder="Enter Mobile Number"
                            name="phone"
                            title="please lengthen this to 10 digits"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                          />
                        </div>
                        {touched.phone && errors.phone && (
                          <small className="errorMessageSignup">
                            {errors.phone}
                          </small>
                        )}
                      </div>
                    </div>
                   
                    {/* Create Password */}
                    <div className="mb-3">
                      <div className=" position-relative">
                        <div className="form-group">
                          <label
                            htmlFor="signup_password"
                            className="form-label signup_div_input"
                          >
                            Password <span className="required_sign">*</span>
                          </label>
                          <input
                            type={showPassword ? "password" : "text"}
                            minLength="6"
                            maxLength="14"
                            title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
                            className={`form-control signup_email_form_control  `}
                            id="signup_password"
                            placeholder="Create Password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete=""
                            onFocus={handleFocus}
                          />
                        </div>
                        {touched.password && errors.password && (
                          <small className="errorMessageSignup">
                            {errors.password}
                          </small>
                        )}
                        {showPassword ? (
                          <FaRegEyeSlash
                            className="signup_input_icons"
                            onClick={handleClickShowPassword}
                          />
                        ) : (
                          <BiShow
                            className="signup_input_icons"
                            onClick={handleClickShowPassword}
                          />
                        )}
                      </div>
                    </div>
                    {/* Confirm Password */}
                    <div className="mb-3">
                      <div className="mb-4 position-relative">
                        <div className="form-group">
                          <label
                            htmlFor="signup_confirm_password"
                            className="form-label signup_div_input"
                          >
                            Confirm Password
                            <span className="required_sign">*</span>
                          </label>
                          <input
                            type={showConfirmPassword ? "password" : "text"}
                            minLength={6}
                            maxLength={14}
                            className={`form-control signup_email_form_control `}
                            id="signup_confirm_password"
                            placeholder="Confirm password"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            autoComplete=""
                            onBlur={handleBlur}
                          />
                        </div>
                        {touched.confirmPassword && errors.confirmPassword && (
                          <small className="errorMessageSignup">
                            {errors.confirmPassword}
                          </small>
                        )}
                        {showConfirmPassword ? (
                          <FaRegEyeSlash
                            className="signup_input_icons"
                            onClick={handleClickShowConfirmPassword}
                          />
                        ) : (
                          <BiShow
                            className="signup_input_icons"
                            onClick={handleClickShowConfirmPassword}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Submit Button */}
                  <div className="signup_submit_div">
                    <button
                      type="submit"
                      className=" signup_submit_button"
                      disabled={isSubmitting}
                    >
                      Create
                    </button>
                  </div>
                  {/* OR LOGIN */}
                  {/* <div className="orLogin_Img">
                    <img
                      src={orLogin}
                      alt="Or Login"
                      className="orLogin_img img-fluid"
                    />
                  </div> */}
                  {/* Google Login */}
                  {/* <div className="signup_google_div ">
                    <FcGoogle className="signup_google_icon" />
                  </div> */}
                  {/* Already Have a account */}
                  {/* <div>
                    <p className="signup_already_have_account_text">
                      Already have an account?
                      <span>
                        <Link
                          to="/login"
                          className="signup_already_have_account_login_text"
                        >
                          Login
                        </Link>
                      </span>
                    </p>
                  </div> */}
                </form>
                {/* Toast */}
                {showToast.message && (
                  <div className="toast-container position-fixed bottom-0 end-0 p-3 ">
                    <div
                      className="toast show create_lead_toast"
                      role="alert"
                      aria-live="assertive"
                      aria-atomic="true"
                    >
                      <div className="toast-header create_lead_toast_header">
                        <strong className="me-auto">
                          {/* Form Submitted Successfully */}
                          {showToast.success ? showToast?.optmessage : "Error"}
                        </strong>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() =>
                            setShowToast({ success: false, message: "" })
                          }
                        />
                      </div>
                      <div className="toast-body">{showToast.message}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
