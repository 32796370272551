import React, { useState, useEffect } from "react";
import bootstrap from "bootstrap";
import UpdateRoleAndPermission from "../pages/UpdateRoleAndPermission";
// React Router
import { Link } from "react-router-dom";
// React Icons
import { TiUserDelete} from "react-icons/ti";
// Import Toast
import Toast from "./Toast";
import { FaEdit, FaRegEye } from "react-icons/fa";
// Controller
import {
  deleteCompanyBySuperAdmin,
} from "../controller/fetchApi";
import Deleteuserconfirmation from "./deleteuserconfirmation";


const CreatedCompanyTable = ({
  tblHead,
  data,
  getAllCompany,
  setcurrdata,
}) => {
   
  // Toast Message Code
  const [showToast, setShowToast] = useState({ success: false, message: "" });
  // update user
  const [updateUser, setupdateUser] = useState(null);

  // Token And Users Data
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;

 
  //user id for deletion and confirmation for deletion
  const [delcompany, setdelCompany] = useState("");
  const [show, setshow] = useState(false);
  // Handle User Delete
  const handleCompanyDelete = async (pin) => {

    const result = await deleteCompanyBySuperAdmin(tokenId, delcompany, setShowToast, pin);
    if (result?.data?.status === 200) {
      // getAllCompany();
      setcurrdata(prev=>!prev);
      setshow(false);
    }
  };

  return (
    <div className="LeadRightSectionTable_body table-responsive">
      <table className="table  ">
         <thead >
          <tr>
            <th scope="col" >{tblHead.firstHead}</th>
            <th scope="col" className="text-left">
            {tblHead.secondHead}
            </th>
            <th scope="col" className="text-left" >
            {tblHead.thirdHead}
            </th>
            <th scope="col" className="text-left " >
            {tblHead.fourthHead}
            </th>
            <th scope="col" className="text-left " > 
            {tblHead.fifthHead}
            </th>
            <th scope="col" className="text-center">
            {tblHead.sixthHead}
            </th>
            <th scope="col" className="text-center" >
            {tblHead.sevenHead}
            </th>
            <th scope="col" className="text-center" >
            {tblHead.eightHead}
            </th>
          </tr>
        </thead>
        <tbody className="dashboard_section1_tableBody">
          {data && data?.length > 0 ? (
            data?.map((user, index) =>(
                
              <tr key={index} className="th-design" style={{borderBottom:"1px solid #cfcdcc"}}>
                <td  >{index + 1}</td>
                <td  ><div>{`${user?.companyName}`}</div></td>
                <td>{user?.companyEmail}</td>
                <td >{user?.companyContact}</td>
                <td >{user?.companyAddress}</td>
                <td className="text-center" >  <Link to="/companydetails"  state={{ userData: user }}>
                    <FaRegEye className="fs-3 super_admin_section2_table_icon" />
                  </Link></td>
                  <td className="text-center" >  <Link 
                  to="/updatecompanydetails"
                      className="Link-button-leads"
                      state={{ userData: user }}
                    >
                         <FaEdit className="fs-4"
      />
                    </Link></td>
                    <td className="text-center" >  <Link
                      className="Link-button-leads"
                      onClick={()=>{
                          setshow(true);
                          setdelCompany(user?.id)
                      }}
                    >
                      
                        <TiUserDelete className="fs-3 super_admin_section2_table_icon"/>
                    </Link></td>
                {/* <td  className="text-left"  style={{display:"flex",gap:'12px',justifyContent:"center",borderBottom:"none"}}>
                  <Link to="/companydetails"  state={{ userData: user }}>
                    <FaRegEye className="fs-3 super_admin_section2_table_icon" />
                  </Link>
  
                  <Link 
                  to="/updatecompanydetails"
                      className="Link-button-leads"
                      state={{ userData: user }}
                    >
                         <FaEdit className="fs-4"
                        style={{ color: "rgba(145, 155, 250, 1)" }}/>
                    </Link>
                    <Link
                      className="Link-button-leads"
                      onClick={()=>{
                          setshow(true);
                          setdelCompany(user?.id)
                      }}
                    >
                      
                        <TiUserDelete className="fs-3 super_admin_section2_table_icon"/>
                    </Link>
                </td> */}
               
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">No Company at this time</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Update Role And Permission Modal */}
     

      {/* NEW Update Role And Permission MODAL START */}
      {updateUser && (
        <div className="NEWMODALupdate">
          <div className="actmodalupdate">
            <div className="closemodalupdate">
              <button
                style={{
                  fontSize: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  color: "grey",
                  border: "none",
                }}
                onClick={() => setupdateUser(null)}
              >
                X
              </button>
            </div>
            <div className="modalContent">
              <UpdateRoleAndPermission
                setcurrdata={setcurrdata}
                lastUser={updateUser}
                setlastUser={setupdateUser}
              />
            </div>
          </div>
        </div>
      )}
      {/* NEWMODAL END */}

    

      {show && (
        <Deleteuserconfirmation
          show={show}
          setShow={setshow}
          handleDelete={handleCompanyDelete}
          deleteId={delcompany}
          sourceName="Company"
        />
      )}

      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CreatedCompanyTable;
