import React, { useEffect, useState, useCallback, useContext } from "react";
// React Icons
import { MdAdd } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsPencil, BsTrash } from "react-icons/bs";
import LeadsRightSectionTable from "./shared/LeadsRightSectionTable";
import { MdVerified } from "react-icons/md";
import { MdOutlineUploadFile } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import Deleteuserconfirmation from "./deleteuserconfirmation";
import filter_icon from "../images/filter_icon.png";
// React Router Dom
import { Link } from "react-router-dom";
import permissionContext from "../pages/PermissionsContext";
// Controllers Api Methods
import {
  getAllLeadByFilter,
  deleteLeads,
  getSingleLead,
  verifyLeads,
  downloadLeads,
  uploadLeads,
} from "../controller/fetchApi";
// Components
import UpdateLead from "../pages/dashboardPages/UpdateLead";
import AssignLeads from "./AssignLeads";
// Import Toast
import Toast from "./Toast";
import Loader from "../pages/Loader";
import FilterSidebar from "./LeadsLeftSection";
import Pagination from "./pagination";
import UpLoader from "../pages/uploader";

const LeadsRightSection = ({
  leadStatus,
  setFilter,
  leadCostumerId,
  setLeadCostumerId,
  filterData,
}) => {
  const [assignlead, setAssignLead] = useState(false);
  const [updatelead, setupdatelead] = useState(false);
  const [updateleadData, setupdateleadId] = useState(null);
  const [updatedLead, setupdatedLead] = useState(false);
  const [updatedLeadStatus,setupdatedLeadStatus] = useState(false)
  // Get lead permission From app.js
  const { leadsPermission } = useContext(permissionContext);

  // Start Toast Code-------
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });

  const closeToast = () => {
    setShowToast({ success: false, message: "", optmessage: "" });
  };
  if (showToast.message) {
    setTimeout(closeToast, 3000);
  }

  // Set PageNo to getAllLead Api
  const [getAllLeadData, setAllLeadsData] = useState([]);
  // const [pageRangeStart, setPageRangeStart] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(12);
  const totalPages = getAllLeadData?.totalPages || 1;
  const showingElements= getAllLeadData?.numberOfElements;
  const totalElements=getAllLeadData?.totalElements;


  const userLeadIdData = JSON.parse(localStorage.getItem("leadId"));
  const leadId = userLeadIdData;

  const userIdTokenData = JSON.parse(localStorage.getItem("user"));

  const userrole = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;
  const tokenId = userIdTokenData?.data?.token;

  const [loading, setloading] = useState(true);
  const getLeadsData = useCallback(async () => {
    const filter = {
      page: pageNo,
      size: pageSize,
      leadStatus:leadStatus?leadStatus:"new",
      ...filterData,
    };
    try {
      // console.log("filter",filter)
      const res = await getAllLeadByFilter(filter, tokenId, setShowToast);
      setAllLeadsData(res);
    } catch (error) {
      setShowToast({
        success: true,
        message: "Something went wrong.",
        optmessage: "Finding lead...",
      });
      console.log(error);
    } finally {
      setloading(false);
    }
  }, [filterData, tokenId, pageNo, setAllLeadsData, pageSize, updatedLead,updatedLeadStatus]);
  //user id for deletion and confirmation for deletion
  const [dellead, setdellead] = useState({
    leadId: null,
    setLeadId: null,
  });
  const [show, setshow] = useState(false);

  // Delete Api Start---------------
  const handleDeleteLead = async (pin) => {
    if (dellead.leadId.length) {
      const response = await deleteLeads(
        dellead.leadId,
        dellead.setLeadId,
        setShowToast,
        tokenId,
        pin
      );
      if (response) {
        setTimeout(() => {
          getLeadsData();
        }, 1000);
        setshow(false);
      }
    } else {
      setShowToast({
        success: true,
        message: "Please select lead to delete.",
        optmessage: "Deleting leads.",
      });
    }
  };

  // Update Btn Click Action Start--------
  const [defaultValue, setDefaultValue] = useState([]);
  const handleUpdateLead = async () => {
    try {
      const singleLeadResult = await getSingleLead(leadId, tokenId);
      if (singleLeadResult) {
        getLeadsData();
        setDefaultValue(singleLeadResult);
      } else {
        setDefaultValue([]);
      }
    } catch (error) {
      console.log("LeadRightSection SingleLead :", error);
      setDefaultValue([]);
    }
  };

  //  Verifyleads Function Start -----

  const handleVerifyLeads = async (leadId, setLeadCostumerId) => {
    if (leadId.length) {
      try {
        await verifyLeads(leadId, setShowToast, tokenId);

        if (verifyLeads) {
          getLeadsData();
        }
      } catch (error) {
        console.log("LeadRightSection SingleLead :", error);
      } finally {
        setLeadCostumerId([]);
      }
    } else {
      setShowToast({
        success: true,
        message: "Please select leads to  Verify.",
        optmessage: " Verifing leads...",
      });
    }
  };

  // Handle Download Api ------
  const handleDownloadLeads = async () => {
    try {
      await downloadLeads(setShowToast, tokenId);
    } catch (error) {
      console.log("LeadRightSection Failed Downloaded:", error);
    }
  };

  // Handle Upload File start ----
  const [selectedFile, setSelectedFile] = useState(null);
  const [Uploading,setDisuploading] = useState(false)
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUploadLeads = async () => {
    if (selectedFile) {
      setDisuploading(true)
      try {
        await uploadLeads(selectedFile, setShowToast, tokenId);
        if (uploadLeads) {
          getLeadsData();
          setDisuploading(false);
        }
      } catch (error) {
        console.log("LeadRightSection Failed Uploading:", error);
      }
    }
  };

  useEffect(() => {
    getLeadsData();
    setupdatedLeadStatus(false)
  }, [getLeadsData]);

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_containers">
     
        <div className="dashboard_content_wrapper">
          {/* Btn Div */}
          <div className="dashboard_leads_btn_mainDiv">
            <div className="filter-btn"  
                  
              style={{  cursor: "pointer" }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setFilter((prev) => !prev);
              }}
            >
            
                Filters:
             
            </div>


            <div className="dashboard_leads_btns_div">
              {/* <FilterSidebar setFilterData={setFilterData} /> */}
              <div className="leads_table_id_col">
                <button
                  className="default-btn"
                  onClick={() =>
                    handleVerifyLeads(leadCostumerId, setLeadCostumerId)
                  }
                >
                  <MdVerified className="leads_table_id_col_icon" />
                  Verify Leads
                </button>
              </div>
              <div
                className="asignbtn"
                onClick={() => {
                  leadCostumerId.length
                    ? setAssignLead(true)
                    : setShowToast({
                        success: true,
                        message: "Please select lead.",
                        optmessage: "Assigning lead...",
                      });
                }}
              >
          
              </div>
              <div className="actionleadbtn">
                <button
                  className="default-btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Actions
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="editDeleteDropdown" >
                    {leadsPermission?.includes("Update") ? (
                      <li
                      // data-bs-toggle="modal"
                      // data-bs-target="#updateLeadModal"
                      >
                        <span
                          className="dropdown-item" style={{color:"#757272", fontSize: "14px" }}
                          
                          onClick={() => {
                            if (updateleadData) {
                              setupdatelead(true);
                            } else {
                              setShowToast({
                                success: true,
                                message: "Please select lead.",      
                                optmessage: "Edit lead...",
                              });
                              // alert("Please select a lead.");
                            }
                          }}
                        >
                          <BsPencil className="dashboard_section1_table_editBtn" />
                          Edit
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* Delete Btn */}
                    {leadsPermission?.includes("Delete") ? (
                      <li>
                        <span
                          className="dropdown-item" style={{color:"#757272", fontSize: "14px" }}
                          onClick={() => {
                            //handleDeleteLead(leadCostumerId,setLeadCostumerId)
                            if (leadCostumerId?.length) {
                              setshow(true);
                              setdellead({
                                leadId: leadCostumerId,
                                setLeadId: setLeadCostumerId,
                              });
                            } else {
                              setShowToast({
                                success: true,
                                message: "Select lead to delete.",
                                optmessage: "Deleting Lead.",
                              });
                            }
                          }}
                        >
                          <BsTrash className="dashboard_section1_table_deleteBtn" />
                          Delete
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* Upload Btn */}
                    {leadsPermission?.includes("Upload") ? (
                      <li
                        data-bs-toggle="modal"
                        data-bs-target="#fileUploadModal"
                      >
                        <span className="dropdown-item" style={{color:"#757272", fontSize: "14px" }}>
                          <MdOutlineUploadFile className="dashboard_section1_table_deleteBtn" />
                          Upload Leads
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* Download Btn */}
                    {leadsPermission?.includes("Download") ? (
                      <li>
                        <span
                          className="dropdown-item" style={{color:"#757272", fontSize: "14px" }}
                          onClick={() => handleDownloadLeads()}
                        >
                          <TbFileDownload className="dashboard_section1_table_deleteBtn" />
                          Download Leads
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </button>
              </div>
              {leadsPermission?.includes("Create") && (
                <div className="asignleadbtn">
                  {/* old button */}
                  <button className="default-btn dashboard_leads_create_link" onClick={()=>setAssignLead(true)}>
                    {/* <Link
                      className="dashboard_leads_create_link"
                     
                    > */}
                      <span >
                        <MdAdd />
                      </span>
                      Assign Lead
                    {/* </Link> */}
                  </button>
                </div>
              )} 
              {leadsPermission?.includes("Create") && (
                <div className="create_leadbtn">
                  {/* old button */}
                  <button className="default-btn">
                    <Link
                      className="dashboard_leads_create_link"
                      to="/create-lead"
                    >
                      <span>
                        <MdAdd />
                      </span>
                      Create Leads
                    </Link>
                  </button>
                </div>
              )} 
            </div>
          </div>
          {/* Table Div */}
         {/* <div style={{height:"88%",display:"flex",flexDirection:"column",justifyContent:"space-between"}}> */}
            <LeadsRightSectionTable
            setupdatedLeadStatus={setupdatedLeadStatus}
            setShowToast={setShowToast}
            loading={loading}
             pageNo={pageNo}
             pageSize={pageSize}
              setDefaultValue={setDefaultValue}
              setupdateleadId={setupdateleadId}
              updateleadData={updateleadData}
              tblHead={{srHead:"Sr No",
                zeroonehead: "Created At",
                zerohead: ["PROJECTMANAGER", "SALESEXECUTIVE"]?.includes(
                  userrole
                )
                  ? "Assigned By"
                  : "Assigned To",
                firstHead: "Name",
                secondHead: "Mobile No",
                contactHead: "Whatsapp",
                callHead: "Call",
                thirdHead: "Lead Source",
                fourthHead: "Lead Status",
                fifthHead: "View",
                sixthHead: "Contact Action",
                seventhHead: "Lead Id",
                statusHead:"Status"
              }}
              redirectLink="/lead-details"
              getAllLeadData={getAllLeadData}
              tableName="leads Table"
            />
          <Pagination 
            maxData={totalElements}
            totalPages={totalPages} 
            totalElements={totalElements}
            showingElements={showingElements}
            pageNo={pageNo}
            setPageNo={setPageNo}
            pageSize={pageSize}
            setPagesize={setPagesize}
          />
         {/* </div> */}
    
          {/* new update lead Modal */}
          {updatelead && updateleadData && (
            <div className="NEWMODALupdate">
              <div className="actmodalupdate">
                <div className="closemodalupdate">
                  <button
                    style={{
                      fontSize: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      color: "grey",
                      border: "none",
                    }}
                    onClick={() => {
                      setupdatelead(false);
                    }}
                  >
                    X
                  </button>
                </div>
                <div className="modalContent">
                  <UpdateLead
                    setupdatelead={setupdatelead}
                    setupdatedLead={setupdatedLead}
                    leadData={updateleadData}
                    leadCostumerId={leadCostumerId}
                    defaultValue={defaultValue}
                    getLeadsData={getLeadsData}
                  />
                </div>
              </div>
            </div>
          )}
          {/* File Upload Modal */}
          <>
            <div
              className="modal fade"
              id="fileUploadModal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header w-100">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form>
                      <label
                        htmlFor="exampleFormControlFile1"
                        className="form-label"
                      >
                        Choose file
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        placeholder="chooser file"
                        id="exampleFormControlFile1"
                        onChange={handleFileChange}
                      />
                    </form>
                  </div>
                
                  <div className="modal-footer">
                     {/* {Uploading?<Loader />:<div> */}
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleUploadLeads}
                      // disabled={Uploading}
                    >
                      Upload
                    </button>
                    {/* </div>
} */}
                  </div>

                </div>
              </div>
            </div>
          </>


          {assignlead && (
            <div className="NEWMODALupdate">
              <div className="actmodalupdate">
                <div className="closemodalupdate">
                  <button
                    style={{
                      fontSize: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      color: "grey",
                      border: "none",
                    }}
                    onClick={() => setAssignLead(false)}
                  >
                    X
                  </button>
                </div>
                <div className="modalContent">
                  <AssignLeads
                    getLeadsData={getLeadsData}
                    setAssignLead={setAssignLead}
                    setLeadCostumerId={setLeadCostumerId}
                  />
                </div>
              </div>
            </div>
          )}

  
          <Deleteuserconfirmation
            show={show}
            setShow={setshow}
            handleDelete={handleDeleteLead}
            sourceName="Lead"
          />
          <Toast showToast={showToast} setShowToast={setShowToast} />
        </div>
     
    </div>
  );
};

export default LeadsRightSection;
