import React, { useState, useEffect } from "react";

// Import CSS
// import "../../styles/dashboardCss/contactCostumerDetails.css";
// Import api function from controller
import { getSingleContact } from "../../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const ContactCostumerDetails = () => {
  const navigate = useNavigate();
  const [getSingleContactData, setSingleContactData] = useState([]);
  // Get Schedule CallId & Toekn Id
  const contactId = JSON.parse(localStorage.getItem("contactId"));
  const userTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userTokenData?.data?.token;
  useEffect(() => {
    getSingleContact(contactId, tokenId).then((res) => {
      setSingleContactData(res);
    });
  }, [contactId, tokenId]);
  return (
    <div className="container-fluid dashboard_create_lead_main_container">
         <span onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </span>
      {/* Company Details */}
      <h3 className="mt-2 mx-2 dashboard_leadView_company_details_heading">
        Company Info
      </h3>
      <p className="mx-2 dashboard_leadView_details_heading_second">
        Lead Id : LI-{getSingleContactData?.leadId}
      </p>
      <div className="row">
        <div className="col-xl-12">
          <div className="d-xl-flex d-md-flex justify-content-around justify-content-center align-items-start">
            <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Company Name
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleContactData?.companyName}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Company Email
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleContactData?.companyEmail}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Company Email
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleContactData?.companyEmail}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
            <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Address
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleContactData?.companyAddress}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Company Contact
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleContactData?.companyContact}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Description */}
      <h4 className="my-2 mx-2 dashboard_leadView_company_details_heading">
        Description<span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
      </h4>
      <div className="row">
        <div className="col-xl-12 my-1 mx-2">
          <p className="lead_view_details_description">
            {getSingleContactData?.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactCostumerDetails;
