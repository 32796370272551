import React from "react";

const Toast = ({ showToast, setShowToast }) => {
 
  return (
    <div>
      {/* Toast */}
      {showToast?.message && (
        <div className="toast-container position-fixed top-0 p-3 " style={{right:"8%"}}>
          <div
            className="toast show create_lead_toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header create_lead_toast_header">
              <strong className="me-auto">{showToast.optmessage||'Form Submitted Successfully'}</strong>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowToast({ success: false, message: "" })}
              />
            </div>
            <div className="toast-body">{showToast?.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Toast;
